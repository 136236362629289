
export class WebpageModule {
  wbWebpageID: number = 0;
  titleAr: string = '';
  titleEn: string = '';
  contentAr: string = '';
  contentEn: string = '';
  type: string = '';
  isVisible: boolean = true;
  mgCompanyID: number = 0;
    }
