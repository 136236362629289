<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Order_Tracking' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Order_Tracking' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start Order Tracking Area -->
<section class="order-tracking-area ptb-70">
    <div class="container">
        <div class="order-tracking-content">

                <h3>{{'Dear' | translate }}, {{ clientName }}</h3>
                <p>{{'Your_Order_is' | translate }} <b>{{ orderStatus }}</b></p>
               
            <br />
            <br />

            <h3>{{'Order_Details' | translate }}</h3>
            <p>{{'Order_No' | translate }}: <b>{{ orderNos }}</b></p>
            <p>{{'Receiver_Name' | translate }}: <b>{{ receiverName }}</b></p>
            <p>{{'Receiver_Phone_No' | translate }}: <b>{{ receiverPhoneNo }}</b></p>
            <p>{{'Delivery_Details' | translate }}: <b>{{ deliveryDetails }}</b></p>




        </div>
    </div>
</section>
<!-- End Order Tracking Area -->
