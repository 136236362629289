<!-- Start Top Header Area -->
<section class="top-header-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <ul class="top-header-contact-info">

                    <li><a routerLink="/tracking"><i class='bx bx-box'></i> {{'Track_your_Order' | translate }}</a></li>
                </ul>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end">
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" (click)="toggleClassLang()">
                                    <img src="{{CurrentLangSrc}}" alt="image">
                                    <span>{{CurrentLangName}} <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu" [class.active]="classApplied">
                                    <button (click)="setLang()" class="dropdown-item d-flex align-items-center">
                                        <img src="{{SecondLangSrc}}" class="shadow-sm" alt="flag">
                                        <span>{{SecondLangName}}</span>
                                    </button>

                                </div>
                            </div>
                        </li>
                        <!--<li>
                    <div class="dropdown currency-switcher d-inline-block">
                        <button class="dropdown-toggle" type="button" (click)="toggleClassCurr()">
                            <span>{{CurrentCurrencyName}} <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu" [class.active]="classAppliedCurr">
                            <button (click)="setCurrency(true)" class="dropdown-item d-flex align-items-center">
                                <span>{{SecondCurrencyName}}</span>
                            </button>
                        </div>
                    </div>
                </li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Top Header Area -->
<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo" style="height:40px"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo" style="height:40px"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Home' | translate }}</a></li>

                        <li class="nav-item" *ngFor="let product of categoryData; let rowIndex = index;">


                            <a [routerLink]="['shop', { catID: product.inCategoryID }]" class="nav-link" >

                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                </ng-template>
                                <ng-template #other_ItemAr>{{ product.nameAr }}</ng-template>
                                <ng-template #other_ItemEn>{{ product.name }}</ng-template>

                            </a>

                        </li>


                        <li class="nav-item"><a [routerLink]="['shop']" class="nav-link">{{'Show_All' | translate }}</a></li>
                        <!--  <li class="nav-item"><a [routerLink]="['shop', { catID: 76}]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Vases' | translate }}</a></li>
     <li class="nav-item"><a [routerLink]="['shop', { catID: 77}]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Perfumes' | translate }}</a></li>-->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'Contact_Us' | translate }}</a></li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <!--<div class="option-item">
                            <div class="search-box">
                                <i class='bx bx-search'></i>
                            </div>
                        </div>-->

                        <div class="option-item">

                            <ul class="wish-cart">
                                <li>
                                    <a routerLink="/cart">
                                        <span class="cart">
                                            <span class="wish-icon">
                                                <i class='bx bx-shopping-bag'></i>
                                                <span class="count" *ngIf="cartProducts.length != undefined">
                                                    {{
                                                          cartProducts.length 
                                                    }}
                                                </span>
                                            </span>
                                            <span class="favorite"> &nbsp; </span>

                                            &nbsp;

                                        </span>
                                    </a>
                                </li>
                            </ul>

                        </div>

                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>



            <div class="container">

          


                <div class="option-inner">
                    <div class="others-option d-flex align-items-center justify-content-center">
                        <!--<div class="option-item">
                <div class="search-box">
                    <i class='bx bx-search'></i>
                </div>
            </div>-->



                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
<!-- Search Overlay -->
<!--<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>-->
<!-- End Search Overlay -->
