import { Injectable } from '@angular/core';
import { ViewItem } from './item/item.module';
import { LocalStoreService } from '../../common/service/local-store.service';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class CartService {
    items: ViewItem[] = [];
     
    constructor(private ls: LocalStoreService) {}


    setItemsData(data: any) {

        this.items = data;
        this.order();

    }

    /**
     * 
     * @param product 
     * @param quantity 
     * Add to cart item
     */ 
    addToCart(product: ViewItem, cartQuantity: number = 1) {


        const check_index = this.items.findIndex(
            (item) => item.inItemID === product.inItemID
        );
        if (check_index !== -1) {
            this.items[check_index].cartQuantity++;
            // console.log('Quantity updated:', this.items);
        } else {
            this.items.push({ ...product, cartQuantity });
            // console.log('The product has been added to cart:', this.items);
        }

        this.ls.setItem('CartItems', this.items);
        this.order();
    }

    /**
     * 
     * @param itemId 
     * increament the cart quantity
     */

    incQtyNumber(itemId: any){
        let findItem = this.items.find(item => item.inItemID === itemId)
        if (findItem && findItem.cartQuantity < 10){
            findItem.cartQuantity += 1
        }
        this.ls.setItem('CartItems', this.items);
        this.order();
    }


    /**
     * 
     * @param itemId 
     * decreement the cart quantity
     */
    decQtyNumber(itemId: any){
        let findItem = this.items.find(item => item.inItemID === itemId)
        if (findItem && findItem.cartQuantity > 1){
            findItem.cartQuantity -= 1
        }
        this.ls.setItem('CartItems', this.items);
        this.order();
    }

    /**
     * product.cartQuantity * (((product.saleTaxPercentage /100) * product.cartPrice) + product.cartPrice)
     * @returns get total
     */
    getTotal() {
        // console.log(this.products);
        const total = this.items.reduce((acc, el) => {
            acc += el.cartQuantity * (((el.saleTaxPercentage / 100) * el.cartPrice) + el.cartPrice);
            return acc;
        }, 0);

        return ((total * 100) / 100).toFixed(2);
    }


    ordersChanged = new Subject<any>()

    order() {
        //your code
        this.ordersChanged.next(this.items)
    }


    /**
     * 
     * @returns get cart items
     */
    getItems() {
        return this.items;
    }

    /**
     * 
     * @param productID 
     * delete item from cart
     */
    deleteFromCart(productID: any) {
        const items = this.items.filter((item) => item.inItemID === productID);
        const index = this.items.indexOf(items[0]);
        if (index > -1) {
            this.items.splice(index, 1);
        }
        this.ls.setItem('CartItems', this.items);
        this.order();
    }

    /**
     * 
     * @returns clear all cart items
     */
    clearCart() {
        this.items = [];

        this.ls.setItem('CartItems', this.items);
        this.order();

        return this.items;
    }
}
