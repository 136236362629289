import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ItemModule, ViewItem } from '../../../appshared/invoice/item/item.module';
import { CategoryModule } from '../../../appshared/invoice/category/category.module';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../appshared/invoice/category/category.service';
import { ItemService } from '../../../appshared/invoice/item/item.service';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../../appshared/invoice/cart.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent {

    public SelectedLanguage: string = "en";
    private sub: any;
    closeModal: any;
    public apiURL: string = "";
    ComID: number = 1;

    constructor(
        public router: Router, private serv: CategoryService, private route: ActivatedRoute, private servItem: ItemService, private loader: AppLoaderService, public translate: TranslateService,

        private cartService: CartService, private toastr: ToastrService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }

    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            if (+params['catID'] > 0) {
                this.Cid = +params['catID'];
                this.FillCategory();
            }
            else {
                this.Cid = 0;
                this.CategoryName = "";
            }
            this.fillItems();
        });


        //this.sub = this.route.params.subscribe((params: { [x: string]: any; }) => {
        //    if (+params['catID'] > 0) {
        //        this.Cid = +params['catID'];
        //    }
        //});
        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID +'/Items/';
       // this.FillCategory();
    }


    sortType: string = "Default";
    Cid: number = 0;
    CategoryName?: string = "";

    currentDate = new Date();


    public dataSourceItems = new MatTableDataSource<ItemModule>();

    datas: any;

    FillCategory() {
        // this.loader.open();
        this.serv.getServiceID(this.Cid, this.ComID)
            .subscribe((res: CategoryModule[]) => {

                this.datas = res;

                if (this.SelectedLanguage != "en") {

                    this.CategoryName = this.datas.nameAr;
                }
                else {

                    this.CategoryName = this.datas.name;
                }

                //this.loader.close();
            },
                (err: any) => {
                    //this.loader.close();
                    console.log(err);
                });
    }

    //////////////////////////////////////////


    onSortChange(event: any) {
        this.sortType = event.target.value;
        this.fillItems();
    }

    fillItems() {
        this.dataSourceItems = new MatTableDataSource<ItemModule>();
        this.loader.open();
        this.servItem.getService(this.ComID ,this.Cid, this.sortType)
            .subscribe((res: ItemModule[]) => {
                this.loader.close();

                for (var i = 0; i < res.length; i++) {
                    res[i].webDiscountFromDate = new Date(res[i].webDiscountFromDate);
                    res[i].webDiscountToDate = new Date(res[i].webDiscountToDate);
                }

                this.dataSourceItems.data = res;


            },
                (err: any) => {
                    this.loader.close();
                    console.log(err);
                });
    }
    onCategoryChange(catID: number, CatName: string | undefined, CatNameAr: string | undefined) {

        this.Cid = catID;

        if (this.SelectedLanguage != "en") {

            this.CategoryName = CatNameAr;
        }
        else {

            this.CategoryName = CatName;
        }

        this.fillItems();
    }




    addToCart(itemM: ItemModule) {

        var price = itemM.salesPrice;
        if (itemM.activateWebDiscount && itemM.webDiscountFromDate < new Date() && itemM.webDiscountToDate > new Date()) {
            price = itemM.webDiscountAmount;
        }

        var product: ViewItem = {


            inItemID: itemM.inItemID,
            name: itemM.name,
            nameAr: itemM.nameAr,
            salesPrice: itemM.salesPrice,
            barcode: itemM.barcode,
            inCategoryID: itemM.inCategoryID,
            description: itemM.description,
            inUnitID: itemM.inUnitID,
            photoPath: itemM.photoPath,
            itemCode: itemM.itemCode,
            unitName: itemM.unitName,
            systemCode: itemM.systemCode,
            categoryNameAr: itemM.categoryNameAr,
            descriptionAr: itemM.descriptionAr,
            categoryName: itemM.categoryName,

            saleTaxPercentage: itemM.saleTaxPercentage,
            isViewWeb: itemM.isViewWeb,
            webDiscountFromDate: itemM.webDiscountFromDate,
            webDiscountToDate: itemM.webDiscountToDate,
            webDiscountAmount: itemM.webDiscountAmount,
            activateWebDiscount: itemM.activateWebDiscount,
            unitNameAr: itemM.unitNameAr,
            webDiscountPercentage: itemM.webDiscountPercentage,
            wholesalePrice: itemM.webDiscountPercentage,

            cartQuantity: 1,
            cartPrice: price


        };

        this.cartService.addToCart(product, 1);

        var transR = this.translate.instant('Your_product_added_to_the_cart');
        this.toastr.success(transR, '');
    }
    

    onClickItem(ItemId: number) {
        this.router.navigate(['product_details', { ItemId: ItemId }]);
    }



    shopFullWidth: number = 1;

    

}
