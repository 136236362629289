import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { ItemModule, ItemUnitsModel } from './item.module';
import { Filter } from '../invoice/invoice.module';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }

  getServiceID(INItemID: number): Observable<ItemModule[]> {
    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/' + INItemID);
  }


  getServicView(IemID: number): Observable<ItemModule[]> {
    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/search/' + IemID);
  }




  getServiceView(ComID: number): Observable<ItemModule[]> {
      return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/search/' + ComID);
  }

  getServiceViewID(ItemID: number): Observable<ItemModule[]> {
      return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/' + ItemID);
  }



  search: string = "";
  getServiceFilter(filters: Filter[], comID: number) {
    this.search = "";

    for (var i = 0; i < filters.length; i++) {
      if (this.search == "") {
        this.search = "?"
      }
      else {
        this.search += "&";
      }
      this.search += filters[i].type + "=" + filters[i].valueID;
    }
    if (this.search == "") {
      this.search = "?CompanyID=" + comID +"&topNo=200";
    }
    else {
      this.search += "&CompanyID=" + comID +"&topNo=0";
    }

    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/SearchFilter/' + this.search);
  }


  getSearchBarcodeWithWholsale(barcode: string, comID: number) {
    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/SearchBarcodeWithWholsale/?CompanyID=' + comID + '&barcode=' + barcode);
  }


  getSearchCollectionItem(ItemID: number, comID: number) {
    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/SearchCollectionItem/?CompanyID=' + comID + '&ItemID=' + ItemID);
  }


  getServiceSearchFilter(filters: Filter[], comID: number, productType: string) {
    this.search = "";

    for (var i = 0; i < filters.length; i++) {
      if (this.search == "") {
        this.search = "?"
      }
      else {
        this.search += "&";
      }
      this.search += filters[i].type + "=" + filters[i].valueID;
    }
    if (this.search == "") {
      this.search = "?CompanyID=" + comID + "&productType=" + productType +"&topNo=200";
    }
    else {
      this.search += "&CompanyID=" + comID + "&productType=" + productType +"&topNo=0";
    }

    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/SearchFilter/' + this.search);
  }


  search2: string = "";
  getSearchFilter(filters: Filter[], comID: number) {
    this.search2 = "";

    for (var i = 0; i < filters.length; i++) {
      if (this.search2 == "") {
        this.search2 = "?"
      }
      else {
        this.search2 += "&";
      }
      this.search2 += filters[i].type + "=" + filters[i].valueID;
    }
    if (this.search2 == "") {
      this.search2 = "?topNo=200";
    }
    else {
      this.search2 += "&topNo=0";
    }
    this.search2 += "&comID=" + comID;

    return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/SearchFilter/' + this.search2);
  }



  getServiceItemUnits(itemID: number): Observable<ItemUnitsModel[]> {
    return this.http.get<ItemUnitsModel[]>(environment.apiURL + 'POS/Web/INItem/ItemUnitsID/?itemID=' + itemID);
  }






  ///////////////////////// web


    getServiceBestSeller(comID: number): Observable<ItemModule[]> {
        return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/BestSeller/?CompanyID=' + comID + '&topNo=20');
    }


    getServiceNewArrival(comID: number): Observable<ItemModule[]> {
        return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/NewArrival/?CompanyID=' + comID + '&topNo=20');
    }


    getServiceCategory(comID: number, category: number, sort: string): Observable<ItemModule[]> {
        return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/searchWeb/?comID=' + comID + '&category=' + category + '&sort=' + sort + '&topNo=20');
    }

    getService(comID: number, category: number, sort: string): Observable<ItemModule[]> {
        return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/searchWeb/?comID=' + comID + '&category=' + category + '&sort=' + sort + '&topNo=200');
    }

    getServiceTag(comID: number, tag: number, sort: string): Observable<ItemModule[]> {
        return this.http.get<ItemModule[]>(environment.apiURL + 'POS/Web/INItem/searchWebTag/?comID=' + comID + '&tag=' + tag + '&sort=' + sort + '&topNo=200');
    }

}
