export const config = {


    apiUrl: 'https://aromaticrose.a3mali.net/api/',
        apiUrl2: 'https://aromaticrose.a3mali.net/',
    
    authRoles: {
        sa: ['SA'], // Only Super Admin has access
        admin: ['SA', 'Admin'], // Only SA & Admin has access
        editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
        user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
        guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
    }
}


//apiUrl: 'https://aromaticrose.a3mali.net/api/',
//    apiUrl2: 'https://aromaticrose.a3mali.net/',


    //apiUrl: 'http://localhost:46076/api/',
    //apiUrl2: 'http://localhost:46076/',
