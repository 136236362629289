<!-- Start Footer Area -->
<footer class="footer-area pt-100 {{bgClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="assets/img/logo.png" alt="image">
                    </a>
                    <p>{{'Footer_AboutTXT' | translate }}</p>
                    <ul class="social-links">
                        <!--<li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>-->
                        <li><a href="https://www.instagram.com/aromaticrose.om" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://wa.me/96894971744" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{'Quick_Information' | translate }}</h3>

                    <ul class="links-list">
                        <li><a routerLink="/home">{{'Home' | translate }}</a></li>
                        <li><a routerLink="/about">{{'About_Us' | translate }}</a></li>
                        <!--<li><a routerLink="/faq">{{'FAQ' | translate }}</a></li>-->
                        <li><a routerLink="/contact">{{'Contact' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{'Shopping' | translate }}</h3>

                    <ul class="links-list">
                        <li><a routerLink="/shop">{{'Shop' | translate }}</a></li>
                        <li><a routerLink="/cart">{{'Shopping_Cart' | translate }}</a></li>
                        <li><a routerLink="/tracking">{{'Tracking_Order' | translate }}</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{'Stay_connected' | translate }}:</h3>

                    <ul class="footer-contact-info">
                        <li><i class="bx bx-map" style="margin-right:5px"></i> <a href="https://www.google.com/maps/dir/23.688258,57.887682" target="_blank"> {{'Footer_Address' | translate }} </a> </li>
                        <li><i class="bx bx-phone-call" style="margin-right:5px"></i> <a href="tel:+96894971744" dir="ltr">+968 949 717 44</a></li>
                        <li><i class="bx bx-message" style="margin-right:5px"></i> <a href="mailto:info@aromaticrose.com">info@aromaticrose.com</a></li>
                        <li><i class="bx bx-time-five" style="margin-right:5px"></i> {{'Footer_Working_Hours' | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p dir="ltr">Copyright 2023 Aromatic Rose All rights reserved | Powered By <a href="http://d-pioneers.com/" target="_blank">Digital Pioneers.</a></p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/terms">{{'Terms_and_Conditions' | translate }}</a></li>
                        <li><a routerLink="/privacy">{{'Privacy_Policy' | translate }}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->
