<div class="container">
    <div class="section-title">
        <h2>{{ 'Vases' | translate }}</h2>
        <p>{{ 'VasesTXT' | translate}}</p>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let item of dataSourceItems.data;">
                <div class="single-products-box">
                    <div class="products-image">
                        <a (click)="onClickItem(item.inItemID)" href="javascript:void(0);" class="d-block">
                            <img [src]="apiURL + item.photoPath" alt="image">
                        </a>
                        <!--<ul class="products-button">
                            <li><a href="apiURL + item.photoPath" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                        </ul>-->
                        <a (click)="addToCart(item)" href="javascript:void(0);" class="add-to-cart-btn"><i class="bx bx-cart"></i> {{ 'Add_to_Cart' | translate}}</a>
                    </div>
                    <div class="products-content">
                        <h3>
                            <a (click)="onClickItem(item.inItemID)" href="javascript:void(0);">
                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                </ng-template>
                                <ng-template #other_ItemAr>{{ item.nameAr }}</ng-template>
                                <ng-template #other_ItemEn>{{ item.name }}</ng-template>
                            </a>
                        </h3>
                        <div class="price">
                            <ng-template *ngIf="item.activateWebDiscount == true && item.webDiscountFromDate < currentDate && item.webDiscountToDate > currentDate ; then other_DiscountPrice; else  other_SalePrice">
                            </ng-template>
                            <ng-template #other_SalePrice>

                                <span class="new-price">{{ ((item.saleTaxPercentage /100) * item.salesPrice) + item.salesPrice | number:'1.3-3' }} {{'OMR' | translate }} </span>

                            </ng-template>
                            <ng-template #other_DiscountPrice>

                                <span class="new-price">{{ ((item.saleTaxPercentage /100) * item.webDiscountAmount) + item.webDiscountAmount | number:'1.3-3' }} {{'OMR' | translate }} </span>
                                <span class="old-price"> {{ ((item.saleTaxPercentage /100) * item.salesPrice) + item.salesPrice | number:'1.3-3' }}</span>

                            </ng-template>

                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
