<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'About_Us' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'About_Us' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
       

        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="about-content">
                    <div [innerHtml]="PageContentHTML"></div>
                </div>
            </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="about-image text-center">
                        <img src="../../../../assets/img/about-img1.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
</section>
<!-- End About Area -->

