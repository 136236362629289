
<div class="card">
    <div class="card-header">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="ms-2">
                    <h4 class="me-1">
                        {{"OTP" | translate }}
                    </h4>

                </div>
            </div>
           
        </div>
    </div>


    <div class="card-body" style="max-height:550px">

       
                       
            <p class="a-o-i">{{'Enter_Valid_OTP' | translate }}</p>
                            <div class="align-items-center">
                                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                            </div>  




        <br />
            <button mat-raised-button color="primary" class="default-btn ms-2" (click)="onSubmit()" >{{"Continue" | translate }}</button>
            <button mat-button color="warn" type="button" class="btn" (click)="dialogRef.close()">{{'Cancel' | translate }}</button>





    </div>
</div>




