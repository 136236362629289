import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Invoice, InvoiceModule } from '../../../appshared/invoice/invoice/invoice.module';
import { DeliveryAddressModule } from '../../../appshared/invoice/delivery-address/delivery-address.module';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryAddressService } from '../../../appshared/invoice/delivery-address/delivery-address.service';
import { CartService } from '../../../appshared/invoice/cart.service';
import { ToastrService } from 'ngx-toastr';
import { PromotionCodeService } from '../../../appshared/invoice/promotion-code/promotion-code.service';
import { InvoiceService } from '../../../appshared/invoice/invoice/invoice.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InsertOTPDialogComponent } from '../../tools/insert-otpdialog/insert-otpdialog.component';
import { DeleteInvoiceElementsS, InvoiceElements } from '../../../appshared/invoice/invoice-elements/invoice-elements.module';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { InvoiceElementsService } from '../../../appshared/invoice/invoice-elements/invoice-elements.service';
import { AccountingTreeService } from '../../../appshared/accounting/accounting-tree/accounting-tree.service';
import { AccountingTreeModule } from '../../../appshared/accounting/accounting-tree/accounting-tree.module';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {


    bsValue = new Date();
    maxDate = new Date();
    minDate = new Date();


    products = this.cartService.getItems();
    total = this.cartService.getTotal();

    public IsServicesTerms: boolean = false;

    ComID: number = 1;
    discount: number = 0;
    isDifferentRecipient: boolean = false;
    isDisabledVoucher: boolean = false;
    mainForm: InvoiceModule = new InvoiceModule();
    public SelectedLanguage: string = "en";
    constructor(private toastr: ToastrService, private cartService: CartService, private accountService: AccountingTreeService,
        private loader: AppLoaderService, public dialog: MatDialog, private servPromotionCode: PromotionCodeService, private servInvoiceElements: InvoiceElementsService, public translate: TranslateService, public servDeliveryAddress: DeliveryAddressService, public servInvoice: InvoiceService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }

        this.maxDate.setDate(this.maxDate.getDate() + 40);
}

    ngOnInit(): void {
        this.mainForm.deliveryMethod = 'Receive from Store';

        this.mainForm.mgCompanyID = this.ComID;
        this.mainForm.mgBranchID = 8;
        this.mainForm.mgBranchStockID = 8;
        this.mainForm.acYearID = 1;
        this.mainForm.sellerID = 1238;
        this.mainForm.type = "Cash";
        this.mainForm.invoiceType = "Draft E-Sale";
        this.mainForm.statusType = "Approved";
        this.mainForm.invoiceTypeAr = "مسودة مبيعات إلكترونية";


        this.mainForm.totalTax = 0;
        this.mainForm.serviceCharge = 0;
        this.countTotal();
    }



    onSenderChange() {
        if (!this.isDifferentRecipient) {
            this.mainForm.receiveName = this.mainForm.clientName; 
            this.mainForm.receivePhoneNo = this.mainForm.phoneNO;
            this.Dialing2 = this.Dialing;
            this.telOptions2 = this.telOptions;
            this.phone_number_standard2 = this.phone_number_standard;
        }
    }
    

    onDifferentRecipientChange() {
        if (!this.isDifferentRecipient) {
            this.mainForm.receiveName = this.mainForm.clientName; 
            this.mainForm.receivePhoneNo = this.mainForm.phoneNO;
            this.Dialing2 = this.Dialing;
            this.telOptions2 = this.telOptions;
            this.phone_number_standard2 = this.phone_number_standard;
        }
        else {
            this.mainForm.receiveName = '';
            this.mainForm.receivePhoneNo = '';
            this.Dialing2 = "968";
            this.telOptions2 = { initialCountry: 'om', preferredCountries: ['om'] };
            this.phone_number_standard2 = '';
        }
    }



    countTotal() {
        var vat = 0;
        this.discount = 0;
        var total = 0;
        for (var i = 0; i < this.products.length; i++) {
            var fromDate = new Date(this.products[i].webDiscountFromDate);
            var toDate = new Date(this.products[i].webDiscountToDate);
            var dateNow = new Date();

            var price = this.products[i].salesPrice;
            if (this.products[i].activateWebDiscount && fromDate < dateNow && toDate > dateNow) {
                price = this.products[i].webDiscountAmount;
            }


            var itemTotal = this.products[i].cartQuantity * price;


            var itemDiscount = 0;
            if (this.mainForm.percentage != '' && this.mainForm.percentage != '0') {
                itemDiscount += ((+this.mainForm.percentage / 100) * itemTotal);
            }



            this.discount += itemDiscount;

            itemTotal = itemTotal - itemDiscount;

           var ItemVAT = ((this.products[i].saleTaxPercentage / 100) * itemTotal)

            vat += ItemVAT
            total += (itemTotal + ItemVAT);


        }

        
        this.mainForm.totalTax = vat;
        this.mainForm.amount = (total + this.mainForm.serviceCharge);


    }


    ///////////////////////////////////////////////////////////////////////////
    Dialing: string = "968";
    telOptions = { initialCountry: 'om', preferredCountries: ['om'] };
    phone_number_standard: any;
    onCountryChange(event) {
        this.Dialing = event.dialCode;
    }

    ///////////////////////////////////////////////////////////////////////////
    Dialing2: string = "968";
    telOptions2 = { initialCountry: 'om', preferredCountries: ['om'] };
    phone_number_standard2: any;
    onCountryChange2(event) {
        this.Dialing2 = event.dialCode;
    }

    onDeliveryMethod() {
        if (this.mainForm.deliveryMethod == "Receive from Store") {

            this.mainForm.serviceCharge = 0;
            this.countTotal();

            this.isAddressDelivery1 = false;    
            this.isAddressDelivery2 = false;    
            this.isAddressDelivery3 = false;    
            this.isAddressDelivery4 = false;    
            this.isAddressDelivery5 = false;
            
            this.addressDeliveryValue1 = 0;    
            this.addressDeliveryValue2 = 0;    
            this.addressDeliveryValue3 = 0;    
            this.addressDeliveryValue4 = 0;    
            this.addressDeliveryValue5 = 0;

            this.addressDeliveryName1 = ''; 
            this.addressDeliveryName2 = ''; 
            this.addressDeliveryName3 = ''; 
            this.addressDeliveryName4 = ''; 
            this.addressDeliveryName5 = '';


            this.addressDeliveryData1 = []; 
            this.addressDeliveryData2 = []; 
            this.addressDeliveryData3 = []; 
            this.addressDeliveryData4 = []; 
            this.addressDeliveryData5 = []; 
        }
        else {
            this.servDeliveryAddress.getServiceParentID(this.ComID, 0)
                .subscribe(res => {
                    this.addressDeliveryData1 = res as DeliveryAddressModule[];
                    
                        this.mainForm.inDeliveryAddressID = this.addressDeliveryData1[0].inDeliveryAddressID;
                        this.addressDeliveryValue1 = this.addressDeliveryData1[0].inDeliveryAddressID;
                        this.addressDeliveryName1 = this.addressDeliveryName(this.addressDeliveryData1[0].type);
                        this.isAddressDelivery1 = true;
                    this.mainForm.serviceCharge = this.addressDeliveryData1[0].serviceCharges;
                    this.countTotal();
                        this.onAddressDelivery1();
                    
                },
                    err => {
                        this.mainForm.inDeliveryAddressID = 0;
                        this.isAddressDelivery1 = false;


                        this.addressDeliveryValue1 = 0;
                        this.addressDeliveryValue2 = 0;
                        this.addressDeliveryValue3 = 0;
                        this.addressDeliveryValue4 = 0;
                        this.addressDeliveryValue5 = 0;

                        this.addressDeliveryName1 = '';
                        this.addressDeliveryName2 = '';
                        this.addressDeliveryName3 = '';
                        this.addressDeliveryName4 = '';
                        this.addressDeliveryName5 = '';


                        this.addressDeliveryData1 = [];
                        this.addressDeliveryData2 = [];
                        this.addressDeliveryData3 = [];
                        this.addressDeliveryData4 = [];
                        this.addressDeliveryData5 = []; 
                    })

        }
    }

    onAddressDelivery1() {
        this.servDeliveryAddress.getServiceParentID(this.ComID, this.addressDeliveryValue1)
            .subscribe(res => {
                this.addressDeliveryData2 = res as DeliveryAddressModule[];
               
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryData2[0].inDeliveryAddressID;
                    this.addressDeliveryValue2 = this.addressDeliveryData2[0].inDeliveryAddressID;
                    this.addressDeliveryName2 = this.addressDeliveryName(this.addressDeliveryData2[0].type);
                this.mainForm.serviceCharge = this.addressDeliveryData2[0].serviceCharges;

                this.addressNameEn = this.addressDeliveryData2[0].nameEn;
                this.addressNameAr = this.addressDeliveryData2[0].nameAr;


                this.countTotal();
                    this.isAddressDelivery2 = true;
                    this.onAddressDelivery2();
                
            },
                err => {
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryValue1;

                    for (var i = 0; i < this.addressDeliveryData1.length; i++) {
                        if (this.addressDeliveryData1[i].inDeliveryAddressID == this.mainForm.inDeliveryAddressID) {
                            if (this.addressDeliveryData1[i].freeDeliveryFromAmount != 0) {
                                if (this.addressDeliveryData2[i].freeDeliveryFromAmount < this.mainForm.amount) {
                                    this.mainForm.serviceCharge = this.addressDeliveryData1[i].serviceCharges;
                                    this.addressNameEn = this.addressDeliveryData1[0].nameEn;
                                    this.addressNameAr = this.addressDeliveryData1[0].nameAr;
                                    this.countTotal();
                                }
                            }
                        }
                    }

                    this.isAddressDelivery2 = false;
                    this.isAddressDelivery3 = false;
                    this.isAddressDelivery4 = false;
                    this.isAddressDelivery5 = false;

                    this.addressDeliveryValue3 = 0;
                    this.addressDeliveryValue4 = 0;
                    this.addressDeliveryValue5 = 0;

                    this.addressDeliveryName3 = '';
                    this.addressDeliveryName4 = '';
                    this.addressDeliveryName5 = '';


                    this.addressDeliveryData3 = [];
                    this.addressDeliveryData4 = [];
                    this.addressDeliveryData5 = [];
                })
    }

    onAddressDelivery2() {
        this.servDeliveryAddress.getServiceParentID(this.ComID, this.addressDeliveryValue2)
            .subscribe(res => {
                this.addressDeliveryData3 = res as DeliveryAddressModule[];
               
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryData3[0].inDeliveryAddressID;
                    this.addressDeliveryValue3 = this.addressDeliveryData3[0].inDeliveryAddressID;
                    this.addressDeliveryName3 = this.addressDeliveryName(this.addressDeliveryData3[0].type);
                this.mainForm.serviceCharge = this.addressDeliveryData3[0].serviceCharges;
                this.addressNameEn = this.addressDeliveryData3[0].nameEn;
                this.addressNameAr = this.addressDeliveryData3[0].nameAr;
                this.countTotal();
                    this.isAddressDelivery3 = true;
                    this.onAddressDelivery3();
                
            },
                err => {
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryValue2;

                    for (var i = 0; i < this.addressDeliveryData2.length; i++) {
                        if (this.addressDeliveryData2[i].inDeliveryAddressID == this.mainForm.inDeliveryAddressID) {
                            if (this.addressDeliveryData2[i].freeDeliveryFromAmount != 0 ) {
                                if (this.addressDeliveryData2[i].freeDeliveryFromAmount < this.mainForm.amount) {
                                    this.mainForm.serviceCharge = this.addressDeliveryData2[i].serviceCharges;
                                    this.addressNameEn = this.addressDeliveryData2[0].nameEn;
                                    this.addressNameAr = this.addressDeliveryData2[0].nameAr;
                                    this.countTotal();
                                }
                            }
                        }
                    }

                    this.isAddressDelivery3 = false;
                    this.isAddressDelivery4 = false;
                    this.isAddressDelivery5 = false;

                    this.addressDeliveryValue4 = 0;
                    this.addressDeliveryValue5 = 0;

                    this.addressDeliveryName4 = '';
                    this.addressDeliveryName5 = '';


                    this.addressDeliveryData4 = [];
                    this.addressDeliveryData5 = [];
                })
    }

    onAddressDelivery3() {
        this.servDeliveryAddress.getServiceParentID(this.ComID, this.addressDeliveryValue3)
            .subscribe(res => {
                this.addressDeliveryData4 = res as DeliveryAddressModule[];
               
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryData4[0].inDeliveryAddressID;
                    this.addressDeliveryValue4 = this.addressDeliveryData4[0].inDeliveryAddressID;
                    this.addressDeliveryName4 = this.addressDeliveryName(this.addressDeliveryData4[0].type);
                this.mainForm.serviceCharge = this.addressDeliveryData4[0].serviceCharges;
                this.addressNameEn = this.addressDeliveryData4[0].nameEn;
                this.addressNameAr = this.addressDeliveryData4[0].nameAr;
                this.countTotal();
                    this.isAddressDelivery4 = true;
                    this.onAddressDelivery4();
                
            },
                err => {
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryValue3;

                    for (var i = 0; i < this.addressDeliveryData3.length; i++) {
                        if (this.addressDeliveryData3[i].inDeliveryAddressID == this.mainForm.inDeliveryAddressID) {
                            if (this.addressDeliveryData3[i].freeDeliveryFromAmount != 0) {
                                if (this.addressDeliveryData3[i].freeDeliveryFromAmount < this.mainForm.amount) {
                                    this.mainForm.serviceCharge = this.addressDeliveryData3[i].serviceCharges;
                                    this.addressNameEn = this.addressDeliveryData3[0].nameEn;
                                    this.addressNameAr = this.addressDeliveryData3[0].nameAr;
                                    this.countTotal();
                                }
                            }
                        }
                    }

                    this.isAddressDelivery4 = false;
                    this.isAddressDelivery5 = false;

                    this.addressDeliveryValue4 = 0;
                    this.addressDeliveryValue5 = 0;

                    this.addressDeliveryName4 = '';
                    this.addressDeliveryName5 = '';


                    this.addressDeliveryData4 = [];
                    this.addressDeliveryData5 = [];
                })
    }


    onAddressDelivery4() {
        this.servDeliveryAddress.getServiceParentID(this.ComID, this.addressDeliveryValue4)
            .subscribe(res => {
                this.addressDeliveryData5 = res as DeliveryAddressModule[];
            
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryData5[0].inDeliveryAddressID;
                    this.addressDeliveryValue5 = this.addressDeliveryData5[0].inDeliveryAddressID;
                    this.addressDeliveryName5 = this.addressDeliveryName(this.addressDeliveryData5[0].type);
                this.mainForm.serviceCharge = this.addressDeliveryData5[0].serviceCharges;
                this.addressNameEn = this.addressDeliveryData5[0].nameEn;
                this.addressNameAr = this.addressDeliveryData5[0].nameAr;
                this.countTotal();
                    this.isAddressDelivery5 = true;
                    this.onAddressDelivery5();
                
            },
                err => {
                    this.mainForm.inDeliveryAddressID = this.addressDeliveryValue4;

                    for (var i = 0; i < this.addressDeliveryData4.length; i++) {
                        if (this.addressDeliveryData4[i].inDeliveryAddressID == this.mainForm.inDeliveryAddressID) {
                            if (this.addressDeliveryData4[i].freeDeliveryFromAmount != 0) {
                                if (this.addressDeliveryData4[i].freeDeliveryFromAmount < this.mainForm.amount) {
                                    this.mainForm.serviceCharge = this.addressDeliveryData4[i].serviceCharges;
                                    this.addressNameEn = this.addressDeliveryData4[0].nameEn;
                                    this.addressNameAr = this.addressDeliveryData4[0].nameAr;
                                    this.countTotal();
                                }
                            }
                        }
                    }

                    this.isAddressDelivery5 = false;

                    this.addressDeliveryValue5 = 0;

                    this.addressDeliveryName5 = '';


                    this.addressDeliveryData5 = [];
                })
    }

    onAddressDelivery5() {

        this.mainForm.inDeliveryAddressID = this.addressDeliveryValue5;

        for (var i = 0; i < this.addressDeliveryData5.length; i++) {
            if (this.addressDeliveryData5[i].inDeliveryAddressID == this.mainForm.inDeliveryAddressID) {
                if (this.addressDeliveryData5[i].freeDeliveryFromAmount != 0) {
                    if (this.addressDeliveryData5[i].freeDeliveryFromAmount < this.mainForm.amount) {
                        this.mainForm.serviceCharge = this.addressDeliveryData5[i].serviceCharges;
                        this.addressNameEn = this.addressDeliveryData5[0].nameEn;
                        this.addressNameAr = this.addressDeliveryData5[0].nameAr;
                        this.countTotal();
                    }
                }
            }
        }

    }



    onPromotionCode() {
        if (this.mainForm.promotionCode != "") {


            this.servPromotionCode.getServiceCode(this.ComID, this.mainForm.promotionCode)
                .subscribe(res => {
                    if (res == "0") {
                        var transR = this.translate.instant('Not_Available');
                        this.toastr.warning(transR, '');
                    }
                    else {
                        var splitted = res.split("-", 2);

                        this.mainForm.percentage = splitted[0];

                        this.countTotal();

                        var transR = this.translate.instant('Added_Successfully');
                        this.toastr.success(transR, '');
                        this.isDisabledVoucher = true;

                    }
                })
        }
    }


    addressDeliveryName(addressDN: string) {
        if (this.SelectedLanguage == 'ar') {
            if (addressDN == 'None') {
                addressDN = 'غير محدد';
            }
            else if (addressDN == 'Country') {
                addressDN = 'الدولة';
            }
            else if (addressDN == 'Governorate') {
                addressDN = 'المحافظة';
            }
            else if (addressDN == 'Wilayat') {
                addressDN = 'الولاية';
            }
            else if (addressDN == 'Village') {
                addressDN = 'القرية';
            }
            else if (addressDN == 'City') {
                addressDN = 'المدينة';
            }
            else if (addressDN == 'Delivery Method') {
                addressDN = 'طريقة التوصيل';
            }
        }

        return addressDN;
    }

    addressNameAr: string = '';
    addressNameEn: string = '';


    isAddressDelivery1: boolean = false;
    isAddressDelivery2: boolean = false;
    isAddressDelivery3: boolean = false;
    isAddressDelivery4: boolean = false;
    isAddressDelivery5: boolean = false;


    addressDeliveryName1: string = '';
    addressDeliveryName2: string = '';
    addressDeliveryName3: string = '';
    addressDeliveryName4: string = '';
    addressDeliveryName5: string = '';


    addressDeliveryValue1: number = 0;
    addressDeliveryValue2: number = 0;
    addressDeliveryValue3: number = 0;
    addressDeliveryValue4: number = 0;
    addressDeliveryValue5: number = 0;

    public addressDeliveryData1: DeliveryAddressModule[];
    public addressDeliveryData2: DeliveryAddressModule[];
    public addressDeliveryData3: DeliveryAddressModule[];
    public addressDeliveryData4: DeliveryAddressModule[];
    public addressDeliveryData5: DeliveryAddressModule[];

    onOrder(IsTerms: boolean) {

        if (this.mainForm.amount == 0 || this.mainForm.clientName == '' || this.mainForm.phoneNO == '' || this.mainForm.receiveName == '' || this.mainForm.receivePhoneNo == '' || IsTerms == false) {

            var transR = this.translate.instant('Please_fill_the_required_fields');
            this.toastr.warning(transR, '');
            return;
        }
        else {

            this.mainForm.dialingCodes = this.Dialing;
            this.mainForm.receiveDialingCodes = this.Dialing2;

            if (this.mainForm.deliveryMethod == "Receive from Store") {
                this.mainForm.receiveAddressTXTAr = "الاستلام من المحل";
                this.mainForm.receiveAddressTXTEn = "Receive from Store";
            }
            else {
                this.mainForm.receiveAddressTXTAr = this.addressNameAr;
                this.mainForm.receiveAddressTXTEn = this.addressNameEn;
                
            }

            this.loader.open();

            this.servInvoice.getSendNotifyOTP(this.ComID, this.mainForm.phoneNO, this.mainForm.dialingCodes)
                .subscribe(res => {

                    this.loader.close();

                    if (res == "success") {


                        let title =  'add' ;
                        let dialogRef: MatDialogRef<any> = this.dialog.open(InsertOTPDialogComponent, {
                            width: '720px',
                            disableClose: true,
                            data: { title: title, payload: '', phoneNo: this.mainForm.phoneNO, dialingCodes: this.mainForm.dialingCodes }
                        })
                        dialogRef.afterClosed()
                            .subscribe(res => {
                                if (!res) {
                                    return;
                                }
                                if (res == 'success') {

                                    this.mainFormAccounting.mgCompanyID = 1;
                                    this.mainFormAccounting.accountName = this.mainForm.clientName;
                                    this.mainFormAccounting.nameAr = this.mainForm.clientName;
                                    this.mainFormAccounting.phoneNo = this.mainForm.phoneNO;
                                    this.mainFormAccounting.dialingCodes = this.mainForm.dialingCodes;
                                    this.mainFormAccounting.parentID = 17;
                                    this.mainFormAccounting.status = "Active";
                                    this.mainFormAccounting.clientType = "Unlimited";
                                    this.mainFormAccounting.type = "Online Client";


                                    this.loader.open();
                                    this.accountService.postService(this.mainFormAccounting).subscribe(
                                        res => {
                                            this.loader.close();
                                            this.mainFormAccounting = res as AccountingTreeModule;

                                            this.insertRecord();
                                        },
                                        err => {

                                            this.loader.close();
                                            var transR = this.translate.instant('Not_Available');
                                            this.toastr.warning(transR, '');
}
                                    );

                                  

                                }
                            })

                    }
                    else {
                        var transR = this.translate.instant('Not_Available');
                        this.toastr.warning(transR, '');

                    }
                }, err => {

                    this.loader.close();
                    var transR = this.translate.instant('Not_Available');
                    this.toastr.warning(transR, '');

                })
        }
    }




    public invoiceList: Invoice[] = [];
    public invoiceElementsList: InvoiceElements[] = [];
    public recordList: any = [];
    public policyList: any = [];
    public recordChequeList: any = [];
    public policyChequeList: any = [];
    public invoice2List: any[] = [];
    public invoiceElements2List: any[] = [];
    public DeleteInvoiceElementsSList: any[] = [];
    public invoiceListUpdate: any[] = [];
    public invoiceElementsListUpdate: any[] = [];



    mainFormAccounting: AccountingTreeModule = new AccountingTreeModule();

    insertRecord() {

     

        // Create Client ID

        this.mainForm.acAccountID = this.mainFormAccounting.acAccountID;
        this.mainForm.payingWay = 'Not Paid';
        this.mainForm.inInvoiceID = 0;
        this.mainForm.inInvoiceNo = 0;

        this.invoiceList = [];
        this.invoiceElementsList = [];
        this.recordList = [];
        this.policyList = [];
        this.recordChequeList = [];
        this.policyChequeList = [];
        this.invoice2List = [];
        this.invoiceElements2List = [];



        this.invoiceList.push(this.servInvoice.transferInvoiceFromModule(this.mainForm));


        for (var i = 0; i < this.products.length; i++) {

            this.invoiceElementsList.push(this.servInvoiceElements.transferElementFromProduct(this.products[i]));

        }

        this.loader.open();
        this.servInvoice.postMultiService(this.invoiceList, this.invoiceElementsList, this.recordList, this.policyList, this.recordChequeList, this.policyChequeList, this.invoice2List, this.invoiceElements2List, this.DeleteInvoiceElementsSList, this.invoiceListUpdate, this.invoiceElementsListUpdate).subscribe(
            res => {
                this.loader.close();

                var ss = this.isNumber(res);

                if (ss) {
                    var transR = this.translate.instant('Added_Successfully');
                    this.toastr.success(transR, '');

                    this.mainForm.inInvoiceID = +res;





                    // Here Payment
                    this.loader.open();

                    this.servInvoice.getThawaniPayment(this.ComID, this.mainForm.inInvoiceID)
                        .subscribe(res => {

                            this.loader.close();

                            
                        }, err => {


                            if (err.url != null && err.url != undefined) {

                                window.location.href = err.url;
                            }
                            else {
                                this.loader.close();
                                var transR = this.translate.instant('Not_Available');
                                this.toastr.warning(transR, '');
                            }

                        })


                }
                else {
                    this.toastr.warning(res, 'Error');
                }

            },
            err => {
                this.loader.close(); console.log(err);
            }
        );

        

    }


    isNumber(value: string | number): boolean {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    }
}
