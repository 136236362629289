import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { CategoryModule } from './category.module';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getServiceview(ComID: number): Observable<CategoryModule[]> {
        return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/GNCategory/search/' + ComID );
    }
  
    getService(ComID: number, types: string): Observable<CategoryModule[]> {
        return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/GNCategory/search/' + ComID + '&' + types);
    }
  
  getServiceCode(ComID: number, types: string, code: string): Observable<CategoryModule[]> {
      return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/GNCategory/searchCode/?mGCompanyID=' + ComID + '&type=' + types + '&Code=' + code);
    }
  
  getServiceParentID(ComID: number, ParentID: number, types: string): Observable<CategoryModule[]> {
      return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/GNCategory/searchParentID/?mGCompanyID=' + ComID + '&parentID=' + ParentID + '&types=' + types);
    }

  getServiceSource(ComID: number, type: string, source: string, sourceNo: number) {
      return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/GNCategory/searchSource/?mGCompanyID=' + ComID + '&type=' + type + '&source=' + source + '&sourceNo=' + sourceNo)
  }



}
