import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {  AccountingTreeModule } from './accounting-tree.module';

@Injectable({
  providedIn: 'root'
})
export class AccountingTreeService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }
    

  postService(formFillData: AccountingTreeModule) {
      return this.http.post(environment.apiURL + 'POS/Web/ACAccount/', formFillData);
  }

  


}
