import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { TagModule } from './tag.module';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(ComID: number): Observable<TagModule[]> {
        return this.http.get<TagModule[]>(environment.apiURL + 'POS/Web/INTag/search/?ComID=' + ComID);
    }


    getServiceID(id: number, ComID: number): Observable<TagModule[]> {
        return this.http.get<TagModule[]>(environment.apiURL + 'POS/Web/INTag/searchID/?id=' + id + '&ComID=' + ComID);
    }

}
