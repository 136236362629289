export class AccountingTreeModule {
  acAccountID: number = 0;
  accountName: string = '';
  number: number = 0;
  natureAccount?: string = '';
  typeAccount?: string = '';
  notes?: string = '';
  address?: string = '';
  phoneNo?: string = '';
  civilID?: string = '';
  crNo?: string = '';
  mgCompanyID?: number = 0;
  parentID?: number = 0;
  nameAr?: string = '';
  variable?: boolean = true;
  email?: string = '';
  dialingCodes?: string = '';
  iataCode?: string = '';
  otherPhoneNo?: string = '';
  dob?: string = '';
  doj?: string = '';
  isActive?: boolean = true;
  type?: string = '';
  noOfBranch?: string = '';
  brandName?: string = '';
  managerName?: string = '';
  businessType?: string = '';
  username?: string = '';
  lastLogin?: Date = new Date();
  broughtBy?: number = 0;
  manageBy?: number = 0;
  status?: string = '';
  gnCategoryFolderID?: number = 0;
  mgUserID?: number = 0;
  commissionPercentage?: number = 0;
  promotionCode?: string = '';
  percentage?: number = 0;
  otpDate?: Date = new Date();
  otpCode?: string = '';
  accountDetails?: string = '';
  debtLimit?: number = 0;
  clientType?: string = '';
  paymentPeriod?: number = 0;
  vatNo?: string = '';
  lastDateUpdate?: Date = new Date();
  deliveryCost?: number = 0;
  receiveCost?: number = 0;
  thawaniCode?: string = '';
  latitude?: string = '';
  longitude?: string = '';
  vehicleNo?: string = '';
  gnCategoryBusinessTypeID?: number = 0;
  gnNationalityID?: number = 0;
}

