import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { CategoryModule } from './category.module';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }
 


  getServiceID(id: number, ComID: number): Observable<CategoryModule[]> {
      return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/INCategory/searchID/?id=' + id + '&ComID=' + ComID);
  }

  getService(ComID: number, type: string): Observable<CategoryModule[]> {
      return this.http.get<CategoryModule[]>(environment.apiURL + 'POS/Web/INCategory/search/?type=' + type + '&ComID=' + ComID);
  }
  
}
