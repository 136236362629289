import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-facility-style-one',
    templateUrl: './facility-style-one.component.html',
    styleUrls: ['./facility-style-one.component.scss']
})
export class FacilityStyleOneComponent implements OnInit {

    public SelectedLanguage: string = "en";
    constructor(public translate: TranslateService) {

        if (this.translate.currentLang != undefined) {
            this.SelectedLanguage = this.translate.currentLang;
        }
        if (this.SelectedLanguage == 'ar') {
            this.facilityContent1 = [
                {
                    icon: 'bx bx-shopping-bag',
                    title: 'تسوق أونلاين',
                    paragraph: 'تسوق سهل وبسيط ودفع آمن'
                },
                {
                    icon: 'bx bxs-car',
                    title: 'توصيل سريع',
                    paragraph: 'توصيل سريع حسب موعد المناسبة'
                },
                {
                    icon: 'bx bx-gift',
                    title: 'منتجات جديدة',
                    paragraph: 'هدايا راقية تواكب العصر'
                },
                {
                    icon: 'bx bx-check-shield',
                    title: 'دفع آمن',
                    paragraph: 'دفع آمن %100 عن طريق منصة ثواني'
                }
            ]
        }
       
}

    ngOnInit(): void {
      
    }

    facilityContent1 = [
        {
            icon: 'bx bx-shopping-bag',
            title: 'Shop Online',
            paragraph: 'Easy and simple shopping.'
        },
        {
            icon: 'bx bxs-car',
            title: 'Fast Delivery',
            paragraph: 'Fast delivery according to the date of the event.'
        },
        {
            icon: 'bx bx-gift',
            title: 'New Products',
            paragraph: 'Classy gifts that keep pace with the times.'
        },
        {
            icon: 'bx bx-check-shield',
            title: 'Secured Payments',
            paragraph: '100% secure payment via the Thawani platform.'
        }
    ]
}
