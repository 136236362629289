<!-- Start Partner Area -->
<section class="partner-area pt-70">
    <div class="container">

        <div class="section-title">
            <h2>{{ 'Gift_by_Occasion' | translate }}</h2>
            <p>{{ 'OccasionTXT' | translate}}</p>
        </div>

        
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlidesOptions">
                <ng-template carouselSlide *ngFor="let item of dataSourceTag.data;">
                    <div class="partner-item">
                        <a (click)="onClickTag(item.inTagID)" href="javascript:void(0);">
                            <img [src]="apiURL + item.photoPath" alt="image">

                            <h6>
                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                </ng-template>
                                <ng-template #other_ItemAr>{{ item.nameAr }}</ng-template>
                                <ng-template #other_ItemEn>{{ item.name }}</ng-template>
                            </h6>
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
<!-- End Partner Area -->
