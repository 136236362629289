import { Component } from '@angular/core';
import { WebpageModule } from '../../../appshared/web/webpage/webpage.module';
import { TranslateService } from '@ngx-translate/core';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { WebpageService } from '../../../appshared/web/webpage/webpage.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
    public PageContentHTML: string = "";
    public PageTitle: string = "";
    private datas: any;
    ComID: number = 1;

    mainForm: WebpageModule = new WebpageModule();

    constructor(private service: WebpageService, public translate: TranslateService, private loader: AppLoaderService, private router: Router) {
        this.router.events.subscribe((event) => {

        });
    }

    ngOnInit(): void {
        this.getAllService();
    }



    public getAllService = () => {
        /*    this.isLoading = true;*/
        this.loader.open();
        this.service.getServiceType("PrivacyPolicy", this.ComID)
            .subscribe(res => {
                this.loader.close();
                this.datas = res as WebpageModule[];
                this.mainForm = this.datas[0];
                this.mainForm.contentAr = this.mainForm.contentAr;
                this.mainForm.contentEn = this.mainForm.contentEn;
                this.mainForm.isVisible = this.mainForm.isVisible;
                this.mainForm.wbWebpageID = this.mainForm.wbWebpageID;
                this.mainForm.titleAr = this.mainForm.titleAr;
                this.mainForm.titleEn = this.mainForm.titleEn;
                this.mainForm.type = this.mainForm.type;
                if (this.translate.currentLang == "ar") {

                    this.PageContentHTML = this.mainForm.contentAr;
                    this.PageTitle = this.mainForm.titleAr;
                }
                else {

                    this.PageContentHTML = this.mainForm.contentEn;
                    this.PageTitle = this.mainForm.titleEn;
                }


            },
                err => {
                    this.loader.close(); console.log(err);
                });

        /*  this.isLoading = false;*/
    }
}
