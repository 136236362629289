import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FeedbackModule } from './feedback.module';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }


    postService(formFillData: FeedbackModule) {
        return this.http.post(environment.apiURL + 'POS/Web/WBFeedback/', formFillData);
  }


}
