import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './appviews/pages/home/home.component';
import { TermsComponent } from './appviews/pages/terms/terms.component';
import { PrivacyComponent } from './appviews/pages/privacy/privacy.component';
import { ContactComponent } from './appviews/pages/contact/contact.component';
import { AboutComponent } from './appviews/pages/about/about.component';
import { ShopComponent } from './appviews/shop/shop/shop.component';
import { CartComponent } from './appviews/shop/cart/cart.component';
import { CheckoutComponent } from './appviews/shop/checkout/checkout.component';
import { ProductDetailsComponent } from './appviews/shop/product-details/product-details.component';
import { NotFoundPageComponent } from './appviews/pages/not-found-page/not-found-page.component';
import { TrackingComponent } from './appviews/shop/tracking/tracking.component';
import { TrackingDetailsComponent } from './appviews/shop/tracking-details/tracking-details.component';
import { PaymentResultComponent } from './appviews/shop/payment-result/payment-result.component';
import { ShopTagComponent } from './appviews/shop/shop-tag/shop-tag.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'terms', component: TermsComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'shop', component: ShopComponent },
    { path: 'shop_tag', component: ShopTagComponent },
    { path: 'cart', component: CartComponent },
    { path: 'checkout', component: CheckoutComponent },
    { path: 'product_details', component: ProductDetailsComponent },
    { path: 'tracking', component: TrackingComponent },
    { path: 'tracking_details', component: TrackingDetailsComponent },
    { path: 'payment_result', component: PaymentResultComponent },
    // Here add new pages component

    {path: '**', component: NotFoundPageComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
