<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Privacy_Policy' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Privacy_Policy' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="customer-service-content">
            <div [innerHtml]="PageContentHTML"></div>
        </div>

     
    </div>
</section>
<!-- End About Area -->
