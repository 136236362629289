<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Shop' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Product_Details' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start Products Details Area -->
<section class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <img [src]="apiURL + mainForm.photoPath" alt="products-image" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h3>
                        <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                        </ng-template>
                        <ng-template #other_ItemAr>{{ mainForm.nameAr }}</ng-template>
                        <ng-template #other_ItemEn>{{ mainForm.name }}</ng-template>
                    </h3>
                    <div class="price">
                        <ng-template *ngIf="mainForm.activateWebDiscount == true && mainForm.webDiscountFromDate < currentDate && mainForm.webDiscountToDate > currentDate ; then other_DiscountPrice; else  other_SalePrice">
                        </ng-template>

                        <ng-template #other_SalePrice>

                            <span class="new-price">{{ ((mainForm.saleTaxPercentage /100) * mainForm.salesPrice) + mainForm.salesPrice | number:'1.3-3' }} {{'OMR' | translate }} </span>

                        </ng-template>
                        <ng-template #other_DiscountPrice>

                            <span class="new-price">{{ ((mainForm.saleTaxPercentage /100) * mainForm.webDiscountAmount) + mainForm.webDiscountAmount | number:'1.3-3' }} {{'OMR' | translate }} </span>
                            <span class="old-price"> {{ ((mainForm.saleTaxPercentage /100) * mainForm.salesPrice) + mainForm.salesPrice | number:'1.3-3' }}</span>

                        </ng-template>

                    </div>
                    <p>
                        <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_DiscriptionAr  ; else other_DiscriptionEn">
                        </ng-template>
                        <ng-template #other_DiscriptionAr>{{ mainForm.descriptionAr }}</ng-template>
                        <ng-template #other_DiscriptionEn>{{ mainForm.description }}</ng-template>
                    </p>
                    <div class="products-meta">
                        <span>
                            <span>{{'Category' | translate }}:</span>
                            <a (click)="onCategoryChange(mainForm.inCategoryID)" href="javascript:void(0);">
                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_categoryAr  ; else other_categoryEn">
                                </ng-template>
                                <ng-template #other_categoryAr>{{ mainForm.categoryNameAr }}</ng-template>
                                <ng-template #other_categoryEn>{{ mainForm.categoryName }}</ng-template>
                            </a>
                        </span>
                        <span>
                            <span>{{'Item_Code' | translate }}:</span>
                            {{mainForm.barcode}}
                        </span>
                        <!--<span>Categories: <a routerLink="/">Chair</a></span>
                        <span>Tag: <a routerLink="/">Chair</a></span>-->
                    </div>


                    <div class="products-add-to-cart">
                        <div class="input-counter">
                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                            <input type="text" max="10" min="1" #qty id="qty" value="1">
                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                        </div>
                        <button type="button" class="default-btn" (click)="addToCart(qty.value)"> {{'Add_to_Cart' | translate }}</button>
                    </div>


                    <!--<a routerLink="/wishlist" class="add-to-wishlist-btn"><i class='bx bx-plus'></i> Add to Wishlist</a>
                    <div class="products-share">
                        <ul class="social">
                            <li><span>Share:</span></li>
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>-->
                </div>
            </div>
            <!--<div class="col-lg-12 col-md-12">
                <div class="products-details-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Description</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab" data-bs-target="#additional-information" type="button" role="tab" aria-controls="additional-information" aria-selected="false">Additional Information</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">Reviews (2)</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel">
                            <p>This story, dazzling in its powerful simplicity and soul-stirring wisdom, is about an Andalusian shepherd boy named Santiago who travels from his homeland in Spain to the Egyptian desert in search of a treasure buried near the Pyramids. Lorem ipsum dolor sit.</p>
                            <ul>
                                <li>Instant <strong>Fiwan</strong> bestseller</li>
                                <li>Translated into 18 languages</li>
                                <li>#1 Most Recommended Book of the year.</li>
                                <li>A neglected project, widely dismissed, its champion written off as unhinged.</li>
                                <li>Yields a negative result in an experiment because of a flaw in the design of the experiment.</li>
                                <li>An Amazon, Bloomberg, Financial Times, Forbes, Inc., Newsweek, Strategy + Business, Tech Crunch, Washington Post Best Business Book of the year</li>
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="additional-information" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Handle Height</td>
                                            <td>40-45″</td>
                                        </tr>
                                        <tr>
                                            <td>Width</td>
                                            <td>24″</td>
                                        </tr>
                                        <tr>
                                            <td>Wheels</td>
                                            <td>12″</td>
                                        </tr>
                                        <tr>
                                            <td>Dimensions</td>
                                            <td>10 × 10 × 10 cm</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Iron</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="products-review-comments">
                                <div class="user-review">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">James Anderson</span>
                                    </div>
                                    <span class="d-block sub-comment">Excellent</span>
                                    <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">Sarah Taylor</span>
                                    </div>
                                    <span class="d-block sub-comment">Video Quality!</span>
                                    <p>Was really easy to implement and they quickly answer my additional questions!</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">David Warner</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Coding!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                                <div class="user-review">
                                    <img src="assets/img/user4.jpg" alt="image">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">King Kong</span>
                                    </div>
                                    <span class="d-block sub-comment">Perfect Video!</span>
                                    <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                                </div>
                            </div>
                            <div class="review-form-wrapper">
                                <h3>Add a review</h3>
                                <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="rating">
                                                <input type="radio" id="star5" name="rating" value="5" /><label for="star5"></label>
                                                <input type="radio" id="star4" name="rating" value="4" /><label for="star4"></label>
                                                <input type="radio" id="star3" name="rating" value="3" /><label for="star3"></label>
                                                <input type="radio" id="star2" name="rating" value="2" /><label for="star2"></label>
                                                <input type="radio" id="star1" name="rating" value="1" /><label for="star1"></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name *">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email *">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <p class="comment-form-cookies-consent">
                                                <input type="checkbox" id="test1">
                                                <label for="test1">Save my name, email, and website in this browser for the next time I comment.</label>
                                            </p>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit">SUBMIT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</section>
<!-- End Products Details Area -->
