import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SlideModule } from './slide.module';

@Injectable({
  providedIn: 'root'
})
export class SlideService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }


  getService(ComID: number, type: string): Observable<SlideModule[]> {
      return this.http.get<SlideModule[]>(environment.apiURL + 'POS/Web/WBSlide/search/?comID=' + ComID + '&type=' + type );
  }

}
