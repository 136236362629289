import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class StyleDirectionService {

    private _mainTheme$: BehaviorSubject<string> = new BehaviorSubject('stylesL');
    private _dirMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    dirMode$: Observable<boolean> = this._dirMode$.asObservable();

    private _renderer: Renderer2;
    private head: HTMLElement;
    private themeLinks: HTMLElement[] = [];
    private themeLinksBootStrap: HTMLElement[] = [];

    theme$: Observable<[string, boolean]> | undefined;


    constructor(
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) document: Document
    ) {
        this.head = document.head;
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    public fillData(value: boolean) {
        this._dirMode$.next(value);

        this.theme$ = combineLatest(this._mainTheme$, this._dirMode$);
        this.theme$.subscribe(async ([mainTheme, dirMode]) => {
            const cssExt = '.css';
            const cssFilename = dirMode ? mainTheme + '-rtl' + cssExt : mainTheme + cssExt;


            if (dirMode) {
                await this.bootstraploadCss('bootstrap-rtl.css');
            }
            else {
                await this.bootstraploadCss('bootstrap.css');
            }


            await this.loadCss(cssFilename);

            if (this.themeLinks.length == 2)
                this._renderer.removeChild(this.head, this.themeLinks.shift());

            if (this.themeLinksBootStrap.length == 2)
                this._renderer.removeChild(this.head, this.themeLinksBootStrap.shift());
        })
    }

    setMainTheme(name: string) {
        this._mainTheme$.next(name);
    }

    setDirMode(value: boolean) {
        this._dirMode$.next(value);
    }

    private async loadCss(filename: string) {
        return new Promise(resolve => {
            const linkEl: HTMLElement = this._renderer.createElement('link');
            this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
            this._renderer.setAttribute(linkEl, 'type', 'text/css');
            this._renderer.setAttribute(linkEl, 'href', filename);
            this._renderer.setProperty(linkEl, 'onload', resolve);
            this._renderer.appendChild(this.head, linkEl);
            this.themeLinks = [...this.themeLinks, linkEl];
        })
    }



    private async bootstraploadCss(filename: string) {
        return new Promise(resolve => {
            const linkEl: HTMLElement = this._renderer.createElement('link');
            this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
            this._renderer.setAttribute(linkEl, 'type', 'text/css');
            this._renderer.setAttribute(linkEl, 'href', filename);
            this._renderer.setProperty(linkEl, 'onload', resolve);
            this._renderer.appendChild(this.head, linkEl);
            this.themeLinksBootStrap = [...this.themeLinksBootStrap, linkEl];
        })
    }

}
