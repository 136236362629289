<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Shop' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Shop' | translate }}</li>
    </ul>
</div>

<!-- End Page Title Area -->
<!-- Start Products Area -->
<section class="products-area ptb-100">
    <div class="container">
        <div class="fiwan-grid-sorting row align-items-center">
            <div class="col-lg-8 col-md-6 result-count">
                <div class="align-items-center">
                    <h4>{{ TagName }}</h4>
                    <p><strong>{{ dataSourceItems.data.length }}</strong> {{'Products_' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 ordering">
                <div>
                    <label>{{ 'Sort_By' | translate }}</label><br /><br />
                    <select class="form-select" (change)="onSortChange($event)">
                        <option value="Default">{{'Default' | translate }}</option>
                        <option value="Low to High">{{'Price_Low_to_High' | translate }}</option>
                        <option value="High to Low">{{'Price_High_to_Low' | translate }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-4 col-md-3" *ngFor="let item of dataSourceItems.data | paginate: { itemsPerPage: 12, currentPage: shopFullWidth }">
                <div class="single-products-item">
                    <div class="products-image">
                        <a (click)="onClickItem(item.inItemID)" href="javascript:void(0);" class="d-block">
                            <img [src]="apiURL + item.photoPath" alt="Image" />
                        </a>
                        <!--<ul class="products-button">
                            <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li>
                            <li><a href="{{Content.mainImg}}" class="popup-btn"><i class='bx bx-scan'></i></a></li>
                            <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li>
                        </ul>-->
                        <a (click)="addToCart(item)" href="javascript:void(0);" class="add-to-cart-btn"><i class="bx bx-cart"></i> {{'Add_to_Cart' | translate }}</a>
                    </div>
                    <div class="products-content">
                        <h3>
                            <a (click)="onClickItem(item.inItemID)" href="javascript:void(0);" class="title">
                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                </ng-template>
                                <ng-template #other_ItemAr>{{ item.nameAr }}</ng-template>
                                <ng-template #other_ItemEn>{{ item.name }}</ng-template>
                            </a>

                        </h3>
                        <div class="price">
                            <ng-template *ngIf="item.activateWebDiscount == true && item.webDiscountFromDate < currentDate && item.webDiscountToDate > currentDate ; then other_DiscountPrice; else  other_SalePrice">
                            </ng-template>
                            <ng-template #other_SalePrice>

                                <span class="new-price">{{ ((item.saleTaxPercentage /100) * item.salesPrice) + item.salesPrice | number:'1.3-3' }} {{'OMR' | translate }} </span>

                            </ng-template>
                            <ng-template #other_DiscountPrice>

                                <span class="new-price">{{ ((item.saleTaxPercentage /100) * item.webDiscountAmount) + item.webDiscountAmount | number:'1.3-3' }} {{'OMR' | translate }} </span>
                                <span class="old-price"> {{ ((item.saleTaxPercentage /100) * item.salesPrice) + item.salesPrice | number:'1.3-3' }}</span>

                            </ng-template>

                        </div>

                    </div>
                    <span class="on-sale" *ngIf="item.activateWebDiscount == true && item.webDiscountFromDate < currentDate && item.webDiscountToDate > currentDate">{{'Sales' | translate }}</span>
                    <!--<span class="out-of-stock" *ngIf="item.activateWebDiscount == true && item.webDiscountFromDate < currentDate && item.webDiscountToDate > currentDate">{{'Most_Sales' | translate }}</span>-->
                    <!-- <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>-->
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls (pageChange)="shopFullWidth = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->
