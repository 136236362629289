import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ItemService } from '../../appshared/invoice/item/item.service';
import { AppLoaderService } from '../service/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ItemModule, ViewItem } from '../../appshared/invoice/item/item.module';
import { MatTableDataSource } from '@angular/material/table';
import { CartService } from '../../appshared/invoice/cart.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-gift-tag',
  templateUrl: './gift-tag.component.html',
  styleUrls: ['./gift-tag.component.scss']
})
export class GiftTagComponent {

    currentDate = new Date();
    sortType: string = "Default";
    
    public SelectedLanguage: string = "en";
    public dataSourceItems = new MatTableDataSource<ItemModule>();
    ComID: number = 1;
    public apiURL: string = "";
    constructor(public router: Router, private route: ActivatedRoute, private servItem: ItemService, private loader: AppLoaderService, public translate: TranslateService, private cartService: CartService, private toastr: ToastrService) { }

    ngOnInit(): void {

        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID + '/Items/';
        this.fillItems();

        if (this.translate.currentLang != undefined) {
            this.SelectedLanguage = this.translate.currentLang;
        }


        if (this.SelectedLanguage == 'ar') {
            this.productsSlidesOptions = {
                loop: true,
                nav: false,
                dots: true,
                autoplayHoverPause: true,
                autoplay: true,
                margin: 10,
                rtl: true,
                navText: [
                    "<i class='bx bx-right-arrow-alt'></i>",
                    "<i class='bx bx-left-arrow-alt'></i>"
                ],
                responsive: {
                    0: {
                        items: 3
                    },
                    576: {
                        items: 3
                    },
                    768: {
                        items: 4
                    },
                    992: {
                        items: 4
                    },
                    1200: {
                        items: 4
                    }
                }
            }
        }
    }

    fillItems() {
        //    this.loader.open();
        this.servItem.getServiceTag(this.ComID, 14, this.sortType)
            .subscribe((res: ItemModule[]) => {
                this.loader.close();

                for (var i = 0; i < res.length; i++) {
                    res[i].webDiscountFromDate = new Date(res[i].webDiscountFromDate);
                    res[i].webDiscountToDate = new Date(res[i].webDiscountToDate);
                }

                this.dataSourceItems.data = res;


            },
                (err: any) => {
                    this.loader.close();
                    console.log(err);
                });
    }




    addToCart(itemM: ItemModule) {

        // var ViewItems = new ViewItem();

        var price = itemM.salesPrice;
        if (itemM.activateWebDiscount && itemM.webDiscountFromDate < new Date() && itemM.webDiscountToDate > new Date()) {
            price = itemM.webDiscountAmount;
        }


        var product: ViewItem = {


            inItemID: itemM.inItemID,
            name: itemM.name,
            nameAr: itemM.nameAr,
            salesPrice: itemM.salesPrice,
            barcode: itemM.barcode,
            inCategoryID: itemM.inCategoryID,
            description: itemM.description,
            inUnitID: itemM.inUnitID,
            photoPath: itemM.photoPath,
            itemCode: itemM.itemCode,
            unitName: itemM.unitName,
            systemCode: itemM.systemCode,
            categoryNameAr: itemM.categoryNameAr,
            descriptionAr: itemM.descriptionAr,
            categoryName: itemM.categoryName,

            saleTaxPercentage: itemM.saleTaxPercentage,
            isViewWeb: itemM.isViewWeb,
            webDiscountFromDate: itemM.webDiscountFromDate,
            webDiscountToDate: itemM.webDiscountToDate,
            webDiscountAmount: itemM.webDiscountAmount,
            activateWebDiscount: itemM.activateWebDiscount,
            unitNameAr: itemM.unitNameAr,
            webDiscountPercentage: itemM.webDiscountPercentage,
            wholesalePrice: itemM.webDiscountPercentage,

            cartQuantity: 1,
            cartPrice: price

        };



        this.cartService.addToCart(product);


        var transR = this.translate.instant('Your_product_added_to_the_cart');
        this.toastr.success(transR, '');
    }




    onClickItem(ItemId: number) {
        this.router.navigate(['product_details', { ItemId: ItemId }]);
    }



    //    productsSlidesOptions: OwlOptions = {
    //        items: 1,
    //        nav: true,
    //        loop: true,
    //        margin: 25,
    //        dots: false,
    //        autoplay: true,
    //        smartSpeed: 1000,
    //        autoplayHoverPause: true,
    //navText: [
    //	"<i class='bx bx-left-arrow-alt'></i>",
    //	"<i class='bx bx-right-arrow-alt'></i>"
    //],
    //        responsive: {
    //            0: {
    //                items: 1
    //            },
    //            414: {
    //                items: 1
    //            },
    //            576: {
    //                items: 2
    //            },
    //            768: {
    //                items: 2
    //            },
    //            992: {
    //                items: 3
    //            },
    //            1200: {
    //                items: 4
    //            }


    //}
    //  }



    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 10,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 3
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            992: {
                items: 4
            },
            1200: {
                items: 4
            }
        }
    }
}
