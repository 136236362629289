import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { PromotionCodeModule } from './promotion-code.module';

@Injectable({
  providedIn: 'root'
})
export class PromotionCodeService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }


    getServiceCode(ComID: number, code: string){
        return this.http.get(environment.apiURL + 'POS/Web/INPromotionCode/searchCode/?mGCompanyID=' + ComID + '&Code=' + code, { responseType: 'text' });
  }

}
