import { Component } from '@angular/core';
import { ItemModule, ViewItem } from '../../../appshared/invoice/item/item.module';
import { ItemPictureModule } from '../../../appshared/invoice/item-picture/item-picture.module';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { ItemService } from '../../../appshared/invoice/item/item.service';
import { CategoryService } from '../../../appshared/invoice/category/category.service';
import { ItemPictureService } from '../../../appshared/invoice/item-picture/item-picture.service';
import { CartService } from '../../../appshared/invoice/cart.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent {

    mainForm: ItemModule = new ItemModule();
    mainFormPicture: ItemPictureModule = new ItemPictureModule();

    public SelectedLanguage: string = "en";
    public itemPictureData: ItemPictureModule[] | undefined;
    public apiURL: string = "";
    private sub: any;
    ItemId: number = 0;
    ComID: number = 1;

    currentDate = new Date();
    private datas: any;

    constructor(public dialog: MatDialog, public translate: TranslateService, private route: ActivatedRoute, private toastr: ToastrService, private router: Router, private loader: AppLoaderService, public servItem: ItemService, private serv: CategoryService, public servItemPicture: ItemPictureService,
        private cartService: CartService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.ItemId = +params['ItemId'];
        });
        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID +'/Items/';
        this.buildItemForm();
        this.fillItemPictures();
    }




    fillItemPictures() {
        // this.loader.open();
        this.servItemPicture.getServiceID(this.ItemId)
            .subscribe((res: ItemPictureModule[]) => {

                this.datas = res as ItemPictureModule[];

                this.mainFormPicture = this.datas;


                this.loader.close();
            },
                (err: any) => {
                    this.loader.close();
                    console.log(err);
                });
    }


    buildItemForm() {

        // this.loader.open();
        this.servItem.getServiceViewID(this.ItemId)
            .subscribe((res: any[]) => {
                this.datas = res as ItemModule[];

                this.datas.webDiscountFromDate = new Date(this.datas.webDiscountFromDate);
                this.datas.webDiscountToDate = new Date(this.datas.webDiscountToDate);


                this.mainForm = this.datas;
                this.loader.close();
            },
                (err: any) => {
                    this.loader.close(); console.log(err);

                });
    }

    ///////////////////////////////////////////////////
    public slug: any;
    public data: any;
    addToCart(qty: any) {

        var price = this.mainForm.salesPrice;
        if (this.mainForm.activateWebDiscount && this.mainForm.webDiscountFromDate < new Date() && this.mainForm.webDiscountToDate > new Date()) {
            price = this.mainForm.webDiscountAmount;
        }

        var product: ViewItem = {


            inItemID: this.mainForm.inItemID,
            name: this.mainForm.name,
            nameAr: this.mainForm.nameAr,
            salesPrice: this.mainForm.salesPrice,
            barcode: this.mainForm.barcode,
            inCategoryID: this.mainForm.inCategoryID,
            description: this.mainForm.description,
            inUnitID: this.mainForm.inUnitID,
            photoPath: this.mainForm.photoPath,
            itemCode: this.mainForm.itemCode,
            unitName: this.mainForm.unitName,
            systemCode: this.mainForm.systemCode,
            categoryNameAr: this.mainForm.categoryNameAr,
            descriptionAr: this.mainForm.descriptionAr,
            categoryName: this.mainForm.categoryName,

            saleTaxPercentage: this.mainForm.saleTaxPercentage,
            isViewWeb: this.mainForm.isViewWeb,
            webDiscountFromDate: this.mainForm.webDiscountFromDate,
            webDiscountToDate: this.mainForm.webDiscountToDate,
            webDiscountAmount: this.mainForm.webDiscountAmount,
            activateWebDiscount: this.mainForm.activateWebDiscount,
            unitNameAr: this.mainForm.unitNameAr,
            webDiscountPercentage: this.mainForm.webDiscountPercentage,
            wholesalePrice: this.mainForm.wholesalePrice,

            cartQuantity: 1,
            cartPrice: price
        };


        this.cartService.addToCart(product, +qty);

        

        var transR = this.translate.instant('Your_product_added_to_the_cart');
        this.toastr.success(transR, '');
    }

   

    counter(i: number) {
        return new Array(i);
    }



    onCategoryChange(catID: any) {

        this.router.navigate(['shop', { catID: catID }]);

    }




}
