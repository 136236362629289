import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from '../../../environments/environment';
import { TagService } from '../../appshared/invoice/tag/tag.service';
import { TranslateService } from '@ngx-translate/core';
import { TagModule } from '../../appshared/invoice/tag/tag.module';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppLoaderService } from '../service/app-loader/app-loader.service';

@Component({
    selector: 'app-occasion-category',
    templateUrl: './occasion-category.component.html',
    styleUrls: ['./occasion-category.component.scss']
})
export class OccasionCategoryComponent implements OnInit {

    public SelectedLanguage: string = "en";
    ComID: number = 1;
    public apiURL: string = "";
    public dataSourceTag = new MatTableDataSource<TagModule>();
    constructor(public router: Router, public translate: TranslateService, private loader: AppLoaderService, private servTag: TagService) {

        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID + '/GnAtt/';
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {
        this.fillTags();
    }

    fillTags() {
           this.loader.open();
        this.servTag.getService(this.ComID)
            .subscribe((res: TagModule[]) => {


                this.loader.close();

                this.dataSourceTag.data = res;


            },
                (err: any) => {
                    this.loader.close();
                    console.log(err);
                });
    }




    onClickTag(tagId) {

        this.router.navigate(['shop_tag', { tagId: tagId }]);
    }


    partnerSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 10,
        rtl: true,
        navText: [
            "<i class='bx bx-right-arrow-alt'></i>",
            "<i class='bx bx-left-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 2
            },
            576: {
                items: 3
            },
            768: {
                items: 4
            },
            992: {
                items: 5
            },
            1200: {
                items: 6
            }
        }
    }

}
