import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../../../appshared/invoice/cart.service';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent {
    IsSuccess: boolean = true;
    private sub: any;
    thisID: number = 0;

    constructor(  private cartService: CartService, private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.thisID = +params['id'];
        });

        if (this.thisID == 0) {
            this.IsSuccess = false;
        }
        else {
            this.cartService.clearCart();
        }
    }



}
