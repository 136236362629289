import { Component } from '@angular/core';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '../../../appshared/masters/category/category.service';
import { FeedbackService } from '../../../appshared/web/feedback/feedback.service';
import { CategoryModule } from '../../../appshared/masters/category/category.module';
import { FeedbackModule } from '../../../appshared/web/feedback/feedback.module';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    public categoryData: CategoryModule[] | undefined;

    public SelectedLanguage: string = "en";
    ComID: number = 1;
    constructor(public service: FeedbackService, public servCategory: CategoryService, private toastr: ToastrService, public translate: TranslateService, private router: Router, private loader: AppLoaderService) {
        if (this.translate.currentLang != undefined) {
            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {
        this.getAllCategory();
    }


    public getAllCategory = () => {
        //this.loader.open();
        this.servCategory.getService(this.ComID, "Enquiry Type")
            .subscribe(res => {
                this.categoryData = res as CategoryModule[];
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);
                });
    }

   
    

    submit(form) {

        if (!this.validateEmail(form.email)) {
            return;
        }


            this.insertRecord(form);

     
    }


    insertRecord(form: any) {


      var  mainForm: FeedbackModule = new FeedbackModule();

        mainForm.email = form.email;
        mainForm.phoneNo = form.number;
        mainForm.message = form.message;
        mainForm.name = form.name;
        mainForm.gnCategoryID = form.categoryID;
        mainForm.mgCompanyID = 1;
        mainForm.status = 'Waiting';

        this.service.postService(mainForm).subscribe(
            (res: any) => {
                /* this.serviceData2 = res as CategoryModule;*/

                this.toastr.success('Added Successfully!', '');

                this.router.navigate(['home']);
                //this.service.formData = new FeedbackModule();

                // this.snackBar.open('Added Successfully!', 'OK', { duration: 4000 });
            },
            (err: any) => { console.log(err); }
        );
    }

    validateEmail(email: string) {
        var emailTest = true;
        if (email != '') {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            console.log(re.test(String(email).toLowerCase()))
            emailTest = re.test(String(email).toLowerCase())
            if (emailTest != true) {
                this.toastr.warning("Please enter valid email")

            }

        }
        return emailTest;
    }


}
