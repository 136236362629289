<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Shopping_Cart' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Shopping_Cart' | translate }}</li>
    </ul>
</div>

<!-- End Page Title Area -->
<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container">

        <div class="cart-totals" *ngIf="products.length == 0; else cartForm"  style="text-align:center">
            <p>{{'Your_cart_is_currently_empty' | translate }}.</p>
            <a routerLink="/shop" class="default-btn">{{'Return_to_Shop' | translate }}</a> 
        </div>
        <ng-template #cartForm>
            <form>
                <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{'Product' | translate }}</th>
                                <th scope="col">{{'Price' | translate }}</th>
                                <th scope="col">{{'Quantity' | translate }}</th>
                                <th scope="col">{{'Total' | translate }}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let product of products; let rowIndex = index;">
                                <td class="product-thumbnail">
                                    {{(rowIndex + 1)}}
                                    <img [src]="apiURL + product.photoPath" alt="item" style="max-width:160px; max-height: 60px" />
                                    <!--<a routerLink="/simple-product"><img src="assets/img/products/products4.jpg" alt="item"></a>-->
                                </td>

                                <td class="product-name">

                                    <!--<a [routerLink]="['/product',product.slug]">-->
                                    <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                    </ng-template>
                                    <ng-template #other_ItemAr>{{ product.nameAr }}</ng-template>
                                    <ng-template #other_ItemEn>{{ product.name }}</ng-template>

                                    <!--</a>-->
                                </td>

                                <td class="product-price">
                                    <span class="unit-amount"> {{ (((product.saleTaxPercentage /100) * product.cartPrice) + product.cartPrice).toFixed(3) }}</span>
                                </td>

                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn2" (click)="minus(product.inItemID)"><i class='bx bx-minus' ></i></span>
                                        <input type="text" min="1" [value]="product.cartQuantity" #qtyCart name="qtyCart" disabled>
                                        <span class="plus-btn2" (click)="plus(product.inItemID)"><i class='bx bx-plus' ></i></span>
                                    </div>
                                </td>

                                <td class="product-subtotal">
                                    <span class="subtotal-amount">{{ ( product.cartQuantity * (((product.saleTaxPercentage /100) * product.cartPrice) + product.cartPrice)).toFixed(3) }}</span>
                                    <a (click)=" onDeleteItem(product.inItemID) " href="javascript:void(0);" class="remove"><i class='bx bx-trash'></i></a>
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>

                <!--<div class="cart-buttons">
            <div class="row align-items-center">
                <div class="col-lg-7 col-sm-12 col-md-7">
                    <div class="shopping-coupon-code">
                        <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                        <button type="submit">APPLY COUPON</button>
                    </div>
                </div>

                <div class="col-lg-5 col-sm-12 col-md-5 text-right">
                    <a routerLink="/shop-left-sidebar-1" class="default-btn"> UPDATE CART</a>
                </div>
            </div>
        </div>-->

                <div class="cart-totals">
                    <ul>
                        <li>  {{'Subtotal' | translate }}  <span>{{ total | number : '1.3-6'  }}</span></li>
                    </ul>
                    <a routerLink="/checkout" class="default-btn"> {{'Proceed_to_Checkout' | translate }}</a>
                </div>
            </form>
        </ng-template>
    </div>
</section>
<!-- End Cart Area -->
