<div class="text-center">
    <!--<h6 class="m-0 pb-16" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h6>-->
    <br />
    <div mat-dialog-content>

        <img class="" src="../../../../assets/img/arom-icon.png" style="max-width:80px" />
        <br />
        <br />
        <mat-spinner [style.margin]="'auto'" [diameter]="40" color="accent"></mat-spinner>
    </div>
</div>




   
