import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../service/local-store.service';
import { TranslateService } from '@ngx-translate/core';
import { StyleDirectionService } from '../service/style-direction/style-direction.service';
import { AppLoaderService } from '../service/app-loader/app-loader.service';
import { CartService } from '../../appshared/invoice/cart.service';
import { CategoryModule } from '../../appshared/invoice/category/category.module';
import { CategoryService } from '../../appshared/invoice/category/category.service';

@Component({
  selector: 'app-navbar-style',
  templateUrl: './navbar-style.component.html',
  styleUrls: ['./navbar-style.component.scss']
})
export class NavbarStyleComponent implements OnInit {

    cartProducts;
    ComID: number = 1;

    CurrentLangSrc: string = "assets/img/flag/us-flag.jpg";
    CurrentLangName: string = "Eng";
    CurrentLangCode: string = "en";

    SecondLangSrc: string = "assets/img/flag/arab-flag.jpg";
    SecondLangName: string = "عربي";
    SecondLangCode: string = "ar";
    currentLang: string = "en";


    CurrentCurrencyName: string = "OMR";
    CurrentCurrencyCode: string = "1";

    SecondCurrencyName: string = "$";
    SecondCurrencyCode: string = "2";
    currentCurrency: string = "1";


    public SelectedLanguage: string = "en";

   // cartProducts = this.cartService.getTotal();

    //mainForm: CurrencyModule = new CurrencyModule();

    constructor(private serv: CategoryService, private localS: LocalStoreService, public translate: TranslateService, private styleDir: StyleDirectionService, private loader: AppLoaderService, private cartService: CartService) { }

    ngOnInit(): void {
        this.fillCategory();

        if (this.translate.currentLang != undefined) {
            this.SelectedLanguage = this.translate.currentLang;
        }

        this.cartService.ordersChanged.subscribe(items =>
            this.cartProducts = items
        )

        var cart = this.localS.getItem('CartItems');

        if (cart != null) {
            this.cartService.setItemsData(cart);
        }


        if (this.localS.getItem('Lang') == 'ar') {
            this.currentLang = "ar";
        }

        if (this.currentLang !== 'ar') {

            this.CurrentLangSrc = "assets/img/flag/us-flag.jpg";
            this.CurrentLangName = "Eng";
            this.CurrentLangCode = "en";

            this.SecondLangSrc = "assets/img/flag/arab-flag.jpg";
            this.SecondLangName = "عربي";
            this.SecondLangCode = "ar";
        }
        else if (this.currentLang === 'ar') {
            this.SecondLangSrc = "assets/img/flag/us-flag.jpg";
            this.SecondLangName = "Eng";
            this.SecondLangCode = "en";

            this.CurrentLangSrc = "assets/img/flag/arab-flag.jpg";
            this.CurrentLangName = "عربي";
            this.CurrentLangCode = "ar";
        }
        //var curID = this.localS.getItem('CurrencyID');
        //if (curID == null) {

        //    this.currentCurrency = "2";
        //    this.setCurrency(false);
        //    this.toggleClassCurr();
        //}
        //else {
        //    this.currentCurrency = curID;
        //    if (this.currentCurrency == '1') {

        //        this.CurrentCurrencyName = "OMR";
        //        this.CurrentCurrencyCode = "1";

        //        this.SecondCurrencyName = "$";
        //        this.SecondCurrencyCode = "2";

        //    }
        //    else {

        //        this.CurrentCurrencyName = "$";
        //        this.CurrentCurrencyCode = "2";

        //        this.SecondCurrencyName = "OMR";
        //        this.SecondCurrencyCode = "1";
        //    }
        //}
    }

    public categoryData: CategoryModule[] | undefined;

    fillCategory() {
        // this.loader.open();
        this.serv.getService(this.ComID, 'Item')
            .subscribe((res: CategoryModule[]) => {
                this.categoryData = res;

                //for (var i = 0; i < res.length; i++) {
                //    if (res[i].inCategoryID == this.Cid) {
                //        if (this.SelectedLanguage != "en") {

                //            this.CategoryName = res[i].nameAr;
                //        }
                //        else {

                //            this.CategoryName = res[i].name;
                //        }
                //    }
               // }

                //this.loader.close();
            },
                (err: any) => {
                    //this.loader.close();
                    console.log(err);
                });
    }



    classApplied = false;
    //classAppliedCurr = false;

    toggleClassLang() {
        this.classApplied = !this.classApplied;
    }

    //toggleClassCurr() {
    //    this.classAppliedCurr = !this.classAppliedCurr;
    //}

    //private datas: any;
    //setCurrency(isRefresh: boolean) {



    //    if (this.currentCurrency !== '1') {

    //        this.CurrentCurrencyName = "OMR";
    //        this.CurrentCurrencyCode = "1";

    //        this.SecondCurrencyName = "$";
    //        this.SecondCurrencyCode = "2";

    //    }
    //    else {

    //        this.CurrentCurrencyName = "$";
    //        this.CurrentCurrencyCode = "2";

    //        this.SecondCurrencyName = "OMR";
    //        this.SecondCurrencyCode = "1";
    //    }

    //    if (this.currentCurrency === "2") {
    //        this.currentCurrency = "1";

    //        //Save
    //        this.localS.setItem('CurrencyID', this.currentCurrency);
    //        this.localS.setItem('CurrencyRate', "1");
    //        this.localS.setItem('CurrencyName', this.CurrentCurrencyName);
    //        this.toggleClassCurr();
    //        if (isRefresh) {
    //            window.location.reload();
    //        }
    //    }
    //    else {
    //        this.currentCurrency = "2";

    //        this.loader.open();
    //        this.service.getServiceId(2)
    //            .subscribe(res => {
    //                this.datas = res as CurrencyModule[];

    //                this.mainForm.gnCurrencyID = this.datas.gnCurrencyID;
    //                this.mainForm.currFormat = this.datas.currFormat;
    //                this.mainForm.isVisible = this.datas.isVisible;
    //                this.mainForm.nameAr = this.datas.nameAr;
    //                this.mainForm.name = this.datas.name;
    //                this.mainForm.notes = this.datas.notes;
    //                this.mainForm.rate = this.datas.rate;


    //                this.localS.setItem('CurrencyID', this.currentCurrency);
    //                this.localS.setItem('CurrencyRate', this.mainForm.rate);
    //                this.localS.setItem('CurrencyName', this.CurrentCurrencyName);
    //                this.toggleClassCurr();
    //                if (isRefresh) {
    //                    window.location.reload();
    //                }
    //                this.loader.close();
    //            },
    //                err => {
    //                    this.loader.close(); console.log(err);


    //                    this.localS.setItem('CurrencyID', this.currentCurrency);
    //                    this.localS.setItem('CurrencyRate', "0.385");
    //                    this.localS.setItem('CurrencyName', this.CurrentCurrencyName);
    //                    this.toggleClassCurr();
    //                    if (isRefresh) {
    //                        window.location.reload();
    //                    }
    //                });


    //    }


    //}


    setLang() {
        if (this.currentLang === "ar") {
            this.currentLang = "en";
        }
        else {
            this.currentLang = "ar";

        }

        

        this.localS.setItem('Lang', this.currentLang);
        this.toggleClassLang();
        
        window.location.reload();
    }


}
