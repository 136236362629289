import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../../appshared/invoice/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

    public SelectedLanguage: string = "en";
    products = this.cartService.getItems();
    total = this.cartService.getTotal();
    public apiURL: string = "";
    ComID: number = 1;

    constructor(
        private toastr: ToastrService, private cartService: CartService, public translate: TranslateService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }

    ngOnInit(): void {
        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID + '/Items/';
}

    /**
     * increament products quantity
     */
    plus(productId: any) {
        this.cartService.incQtyNumber(productId)
        this.total = this.cartService.getTotal();
    }


    /**
     * decreament product quantity
     */
    minus(productId: any) {
        this.cartService.decQtyNumber(productId)
        this.total = this.cartService.getTotal();
    }

    onDeleteItem(productID: any) {
        this.cartService.deleteFromCart(productID);


        var transR = this.translate.instant('Deleted_Successfully');
        this.toastr.warning(transR, '');

        this.total = this.cartService.getTotal();
    }
}
