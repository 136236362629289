import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { InvoiceService } from '../../../appshared/invoice/invoice/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-insert-otpdialog',
  templateUrl: './insert-otpdialog.component.html',
  styleUrls: ['./insert-otpdialog.component.scss']
})
export class InsertOTPDialogComponent {

    ComID: number = 1;
    otp: string;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public servInvoice: InvoiceService, private toastr: ToastrService, public translate: TranslateService,
        public dialogRef: MatDialogRef<InsertOTPDialogComponent>,
        private loader: AppLoaderService

    ) { }


    ngOnInit() {
        
    }
    config = {
        allowNumbersOnly: false,
        length: 4,
        isPasswordInput: false,
        disableAutoFocus: false,
        placeholder: '',
        inputStyles: {
            'width': '50px',
            'height': '50px'
        }
    };

    onOtpChange(otp) {
        this.otp = otp;
    }


    onSubmit() {

        if (this.otp.length > 3) {

            this.loader.open();
            this.servInvoice.getCheckOTP(this.ComID, this.otp, this.data.phoneNo, this.data.dialingCodes)
                .subscribe(res => {
                    this.loader.close();
                    if (res == "success") {

                        this.dialogRef.close("success");

                    }
                    else {
                        var transR = this.translate.instant('Wrong_OTP');
                        this.toastr.warning(transR, '');

                    }
                }, err => {

                    this.loader.close();
                    var transR = this.translate.instant('Wrong_OTP');
                    this.toastr.warning(transR, '');

                })
        }
    }
}
