<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Contact_Us' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Contact_Us' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start Contact Area -->
<section class="contact-area pt-100">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <div class="select-box">
                                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="{{'Name' | translate }}">
                                        </div>
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">{{'Name_is_required' | translate }}</div>
                                            <div *ngIf="name.errors.minlength">{{'Name_is_minimum' | translate }}{{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">{{'Name_is_maximum_50_character' | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <div class="select-box">
                                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="{{'Email' | translate }}">
                                        </div>
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">{{'Email_Address_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <div class="select-box">
                                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="{{'PhoneNo' | translate }}">
                                        </div>
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">{{'Phone_number_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <div class="select-box">
                                            <select required #categoryID="ngModel" name="categoryID" ngModel class="form-control form-select">
                                                <option value="" disabled selected>{{'Enquiry_Type' | translate }}</option>
                                                <option *ngFor="let option of categoryData" [value]="option.gnCategoryID">
                                                    <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_contentAr  ; else other_contentEn">
                                                    </ng-template>
                                                    <ng-template #other_contentAr>{{ option.nameAr }}</ng-template>
                                                    <ng-template #other_contentEn>{{ option.name }}</ng-template>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <div class="select-box">
                                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="{{'Message' | translate }}"></textarea>
                                        </div>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">{{'Message_is_required' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">{{'Send_Message' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>{{'Address' | translate }}</h3>
                        <p>{{'Footer_Address' | translate }}</p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <h3>{{'Contact_Details' | translate }}</h3>
                        <p>Mobile: <a href="tel:+96894971744">+968 94971744</a></p>
                        <p>E-mail: <a href="mailto:info@aromaticrose.com">info@aromaticrose.com</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>{{'Working_Hours' | translate }}</h3>
                        <p>{{'Footer_Working_Hours' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
<!-- Start Map Area -->
<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14614.623868469283!2d57.8876819!3d23.6882581!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8dc5f0568da341%3A0x88ebf71068d40657!2zQXJvbWF0aWMgUm9zZSAtINin2YTZiNix2K8g2KfZhNi52LfYsdmK!5e0!3m2!1sen!2som!4v1698792005686!5m2!1sen!2som"></iframe>
    <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>-->
</div>
<!-- End Map Area -->
