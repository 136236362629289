<!-- Start Main Banner Area -->
<div class="home-banner-area">
    <div class="container-fluid">
        <div class="home-slides">
            <owl-carousel-o [options]="homeSlidesOptions">
                <ng-template carouselSlide *ngFor="let item of dataSourceSlide.data;">
                    <div class="main-banner-area">
                        <div class="row">
                            <div class="col-lg-5 col-md-12">
                                <div class="main-banner-content">

                                    <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                    </ng-template>
                                    <ng-template #other_ItemAr>
                                        <span class="sub-title">{{item.textAr1}}</span>
                                        <h3>{{item.textAr2}}</h3>
                                        <p>{{item.textAr3}}</p>
                                        <a href="{{item.link}}" class="default-btn">{{item.buttonTextAr}}</a>
                                    </ng-template>
                                    <ng-template #other_ItemEn>
                                        <span class="sub-title">{{item.textEn1}}</span>
                                        <h1>{{item.textEn2}}</h1>
                                        <p>{{item.textEn3}}</p>
                                        <a href="{{item.link}}" class="default-btn">{{item.buttonTextEn}}</a>
                                    </ng-template>


                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="main-banner-image text-center" style="background-image: url({{ apiURL + item.photoPath1 }}); ">
                                    <img [src]="apiURL + item.photoPath1" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>


</div>
<!-- End Main Banner Area -->
