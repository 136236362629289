import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SlideService } from '../../../../appshared/web/slide/slide.service';
import { AppLoaderService } from '../../../../common/service/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { SlideModule } from '../../../../appshared/web/slide/slide.module';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    public SelectedLanguage: string = "en";
    public dataSourceSlide = new MatTableDataSource<SlideModule>();
    ComID: number = 1;
    public apiURL: string = "";
    constructor(public router: Router, private route: ActivatedRoute, private servSlide: SlideService, private loader: AppLoaderService, public translate: TranslateService) { }

    ngOnInit(): void {

        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID + '/GnAtt/';
        this.fillSlide();

        if (this.translate.currentLang != undefined) {
            this.SelectedLanguage = this.translate.currentLang;
        }


    }

    fillSlidesOptions() {


        if (this.SelectedLanguage == 'ar') {


            this.homeSlidesOptions = {
                loop: true,
                nav: true,
                dots: false,
                autoplayHoverPause: true,
                autoplay: true,
                animateOut: 'fadeOut',
                rtl: true,
                animateIn: 'fadeIn',
                margin: 5,
                navText: [
                    "<i class='bx bx-right-arrow-alt'></i>",
                    "<i class='bx bx-left-arrow-alt'></i>"
                ],
                responsive: {
                    0: {
                        items: 1
                    },
                    576: {
                        items: 1
                    },
                    768: {
                        items: 1
                    },
                    992: {
                        items: 1
                    },
                    1200: {
                        items: 1
                    }
                }
            }
        }
        else {

            this.homeSlidesOptions = {
                loop: true,
                nav: true,
                dots: false,
                autoplayHoverPause: true,
                autoplay: true,
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                margin: 5,
                navText: [
                    "<i class='bx bx-left-arrow-alt'></i>",
                    "<i class='bx bx-right-arrow-alt'></i>"
                ],
                responsive: {
                    0: {
                        items: 1
                    },
                    576: {
                        items: 1
                    },
                    768: {
                        items: 1
                    },
                    992: {
                        items: 1
                    },
                    1200: {
                        items: 1
                    }
                }
            }
        }
    }

    fillSlide() {
        //    this.loader.open();
        this.servSlide.getService(this.ComID, 'Slide')
            .subscribe((res: SlideModule[]) => {
                this.loader.close();


                this.dataSourceSlide.data = res;
                this.fillSlidesOptions();

            },
                (err: any) => {
                    this.loader.close();
                    console.log(err);
                });
    }


    homeSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		margin: 5,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}
