
<section class="error-area ptb-100">
    <div class="container">

        <div class="error-content" *ngIf="IsSuccess">
            <h3 style="color:forestgreen">{{'Payment_Process_is_Successfully_Done' | translate }}</h3>
            <p>{{'PaymentSuccessTXT' | translate }} {{thisID}}</p>
            <a routerLink="/" class="default-btn"> {{'Back_to_Homepage' | translate }}</a>
        </div>

        <div class="error-content" *ngIf="!IsSuccess">
            <h3 style="color:darkred">{{'Payment_Process_is_Failed' | translate }}</h3>
            <p>{{'PaymentFailedTXT' | translate }}</p>
            <a routerLink="/" class="default-btn"> {{'Back_to_Homepage' | translate }}</a>
        </div>

    </div>
</section>
