import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { DeliveryAddressModule } from './delivery-address.module';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAddressService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }

    getServiceParentID(ComID: number, ParentID: number): Observable<DeliveryAddressModule[]> {
        return this.http.get<DeliveryAddressModule[]>(environment.apiURL + 'POS/Web/INDeliveryAddress/searchParentID/?mGCompanyID=' + ComID + '&parentID=' + ParentID);
    }

  getService(ComID: number): Observable<DeliveryAddressModule[]> {
      return this.http.get<DeliveryAddressModule[]>(environment.apiURL + 'POS/Web/INDeliveryAddress/search/' + ComID);
  }

 
}
