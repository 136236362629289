import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '../../../appshared/invoice/invoice/invoice.service';
import { InvoiceModule } from '../../../appshared/invoice/invoice/invoice.module';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.scss']
})
export class TrackingDetailsComponent implements OnInit {

    public SelectedLanguage: string = "en";
    ComID: number = 1;
    private sub: any;
    orderNo: number = 0;
    phoneNo: string = "";
    private datas: any;


    clientName: string = "";
    orderStatus: string = "";
    orderNos: string = "";
    receiverName: string = "";
    receiverPhoneNo: string = "";
    deliveryDetails: string = "";
   


    constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private loader: AppLoaderService, public translate: TranslateService, public servInvoice: InvoiceService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }


    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            this.orderNo = +params['orderNo'];
            this.phoneNo = params['phoneNo'];
        });

        this.buildItemForm();
    }


    buildItemForm() {

        this.loader.open();
        this.servInvoice.getServiceTrack(this.ComID ,this.orderNo, this.phoneNo)
            .subscribe(res => {
                this.datas = res as InvoiceModule[];

                this.clientName = this.datas[0].accountName;
                this.orderStatus = this.datas[0].statusType;
                this.receiverName = this.datas[0].receiveName;
                this.orderNos = this.datas[0].inInvoiceNo;
                this.receiverPhoneNo = this.datas[0].receivePhoneNo;
                this.deliveryDetails = this.datas[0].deliveryAddressTXTEn;

                if (this.SelectedLanguage == 'ar') {
                    this.deliveryDetails = this.datas[0].deliveryAddressTXTAr;
                    if (this.datas[0].statusType == "New Order") {
                        this.orderStatus ='طلب جديد'
                    }
                    if (this.datas[0].statusType == "In Progress") {
                        this.orderStatus ='قيد الإجراء'
                    }
                    if (this.datas[0].statusType == "Read To Deliver") {
                        this.orderStatus ='جاهز للتسليم'
                    }
                    if (this.datas[0].statusType == "Shipped") {
                        this.orderStatus ='تم الشحن'
                    }
                    if (this.datas[0].statusType == "Delivered") {
                        this.orderStatus ='تم التوصيل'
                    }
                    if (this.datas[0].statusType == "Refund") {
                        this.orderStatus ='مسترجع'
                    }
                }
                    
                this.loader.close();
            },
                err => {
                    this.loader.close(); console.log(err);

                    var transR = this.translate.instant('Not_Available');
                    this.toastr.warning(transR, '');

                    this.router.navigate(['tracking']);

                });
    }


}
