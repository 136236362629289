<!-- Start Facility Area -->
<section class="facility-area pt-100 bg-f5f5f5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let Content of facilityContent1;">
                <div class="single-facility-box">
                    <div class="item">
                        <div class="icon">
                            <i class='{{Content.icon}}'></i>
                        </div>
                        <h3>{{Content.title}}</h3>
                        <p>{{Content.paragraph}}</p>
                    </div>
                </div>
                </div>
        </div>
    </div>
</section>
<!-- End Facility Area -->
