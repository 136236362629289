import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { ItemPictureModule } from './item-picture.module';

@Injectable({
  providedIn: 'root'
})
export class ItemPictureService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }



  getService(ComID: number): Observable<ItemPictureModule[]> {
    return this.http.get<ItemPictureModule[]>(environment.apiURL + 'POS/Web/ItemPictureModule/search/?mGCompanyID=' + ComID );
  }

  getServiceID(inItemID: number): Observable<ItemPictureModule[]> {
    return this.http.get<ItemPictureModule[]>(environment.apiURL + 'POS/Web/INItemPicture/searchID/?inItemID=' + inItemID );
  }



}
