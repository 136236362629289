<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Checkout' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Checkout' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start Checkout Area -->
<section class="checkout-area ptb-100">
    <div class="container">
        <!--<div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Returning customer? <a routerLink="/profile-authentication">Click here to login</a></span>
        </div>-->

        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">{{'Billing_Details' | translate }}</h3>

                        <div class="row">





                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>{{'Delivery_Method' | translate }}<span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control form-select" #deliveryMethod="ngModel" name="deliveryMethod" [(ngModel)]="mainForm.deliveryMethod" [value]="mainForm.deliveryMethod" style="padding-top:5px" (change)="onDeliveryMethod()">
                                            <option value="Receive from Store">{{'Receive_from_Store' | translate }}</option>
                                            <option value="Delivery to Client">{{'Delivery_To_Home' | translate }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-12 col-md-6" *ngIf="isAddressDelivery1">
                                <div class="form-group">
                                    <label>{{addressDeliveryName1}}<span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control form-select" #deliveryMethod1="ngModel" name="deliveryMethod1" [(ngModel)]="addressDeliveryValue1" style="padding-top:5px" (change)="onAddressDelivery1()">
                                            <option *ngFor="let option of addressDeliveryData1" [value]="option.inDeliveryAddressID">
                                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then addressAr1  ; else addressEn1">
                                                </ng-template>
                                                <ng-template #addressAr1>{{ option.nameAr }}</ng-template>
                                                <ng-template #addressEn1>{{ option.nameEn }}</ng-template>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6" *ngIf="isAddressDelivery2">
                                <div class="form-group">
                                    <label>{{addressDeliveryName2}}<span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control form-select" #deliveryMethod2="ngModel" name="deliveryMethod2" [(ngModel)]="addressDeliveryValue2" style="padding-top:5px" (change)="onAddressDelivery2()">
                                            <option *ngFor="let option of addressDeliveryData2" [value]="option.inDeliveryAddressID">
                                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then addressAr2  ; else addressEn2">
                                                </ng-template>
                                                <ng-template #addressAr2>{{ option.nameAr }}</ng-template>
                                                <ng-template #addressEn2>{{ option.nameEn }}</ng-template>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6" *ngIf="isAddressDelivery3">
                                <div class="form-group">
                                    <label>{{addressDeliveryName3}}<span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control form-select" #deliveryMethod3="ngModel" name="deliveryMethod3" [(ngModel)]="addressDeliveryValue3" style="padding-top:5px" (change)="onAddressDelivery3()">
                                            <option *ngFor="let option of addressDeliveryData3" [value]="option.inDeliveryAddressID">
                                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then addressAr3  ; else addressEn3">
                                                </ng-template>
                                                <ng-template #addressAr3>{{ option.nameAr }}</ng-template>
                                                <ng-template #addressEn3>{{ option.nameEn }}</ng-template>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6" *ngIf="isAddressDelivery4">
                                <div class="form-group">
                                    <label>{{addressDeliveryName4}}<span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control form-select" #deliveryMethod4="ngModel" name="deliveryMethod4" [(ngModel)]="addressDeliveryValue4" style="padding-top:5px" (change)="onAddressDelivery4()">
                                            <option *ngFor="let option of addressDeliveryData4" [value]="option.inDeliveryAddressID">
                                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then addressAr4  ; else addressEn4">
                                                </ng-template>
                                                <ng-template #addressAr4>{{ option.nameAr }}</ng-template>
                                                <ng-template #addressEn4>{{ option.nameEn }}</ng-template>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6" *ngIf="isAddressDelivery5">
                                <div class="form-group">
                                    <label>{{addressDeliveryName5}}<span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-control form-select" #deliveryMethod5="ngModel" name="deliveryMethod5" [(ngModel)]="addressDeliveryValue5" style="padding-top:5px" (change)="onAddressDelivery5()">
                                            <option *ngFor="let option of addressDeliveryData5" [value]="option.inDeliveryAddressID">
                                                <ng-template *ngIf="SelectedLanguage == 'ar' ; then addressAr5  ; else addressEn5">
                                                </ng-template>
                                                <ng-template #addressAr5>{{ option.nameAr }}</ng-template>
                                                <ng-template #addressEn5>{{ option.nameEn }}</ng-template>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{'Client_Name' | translate }} <span class="required">*</span></label>
                                    <div class="select-box">
                                        <input type="text" class="form-control" required #clientName="ngModel" name="clientName" [(ngModel)]="mainForm.clientName" (ngModelChange)="onSenderChange()">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>{{'Client_Phone_No' | translate }} <span class="required">*</span></label>
                                    <div class="select-box">
                                        <input type="text" required ng2TelInput #phone_number name="phone_number" class="form-control" [(ngModel)]="mainForm.phoneNO" (ngModelChange)="onSenderChange()" [(value)]="phone_number_standard" (countryChange)="onCountryChange($event)" [ng2TelInputOptions]="telOptions" />

                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" #isDifferentRecipients="ngModel" name="isDifferentRecipients" [(ngModel)]="isDifferentRecipient" [(value)]="phone_number_standard2" (ngModelChange)="onDifferentRecipientChange()">
                                    <label class="form-check-label" for="isDifferentRecipients">{{'Gift_to_Another_Person' | translate }}</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>{{'Receiver_Name' | translate }} <span class="required">*</span></label>
                                    <div class="select-box">
                                        <input type="text" class="form-control" required #receiveName="ngModel" name="receiveName" [(ngModel)]="mainForm.receiveName" [disabled]="!isDifferentRecipient">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>{{'Receiver_Phone_No' | translate }} <span class="required">*</span></label>
                                    <div class="select-box">
                                        <input type="text" required ng2TelInput #phone_number2 name="phone_number2" class="form-control" [(ngModel)]="mainForm.receivePhoneNo" (countryChange)="onCountryChange2($event)" [ng2TelInputOptions]="telOptions2" [disabled]="!isDifferentRecipient" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{'Message_in_the_gift' | translate }}</label>
                                    <div class="select-box">
                                        <textarea class="form-control" #attachmentTXT="ngModel" name="attachmentTXT" [(ngModel)]="mainForm.attachmentTXT"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{'Delivery_Time' | translate }}</label>
                                    <div class="select-box">

                                        <input type="text" class="form-control" value="" name="submitDate" #submitDate="ngModel" [(ngModel)]="mainForm.submitDate"
                                               bsDatepicker [bsValue]="bsValue"
                                       [minDate]="minDate"
                                       [maxDate]="maxDate" [daysDisabled]="[6,0]" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }">

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">{{'Your_Order' | translate }}</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr *ngFor="let product of products">
                                        <td class="product-name">
                                            <ng-template *ngIf="SelectedLanguage == 'ar' ; then other_ItemAr  ; else other_ItemEn">
                                            </ng-template>
                                            <ng-template #other_ItemAr>{{ product.nameAr }}</ng-template>
                                            <ng-template #other_ItemEn>{{ product.name }}</ng-template>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{ ( product.cartQuantity * (((product.saleTaxPercentage /100) * product.cartPrice) + product.cartPrice)).toFixed(3) }}</span>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td class="order-subtotal"><span>{{'Cart_Subtotal' | translate }} </span> </td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">{{ total| number : '1.3-6'  }}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="order-subtotal"><span>{{'Promotion_Code' | translate }}</span></td>
                                        <td class="order-subtotal-price">
                                            <div class="input-group mb-3">

                                                <input type="text" class="form-control" #promotionCode="ngModel" name="promotionCode" [(ngModel)]="mainForm.promotionCode" [disabled]="isDisabledVoucher">

                                                <button type="submit" class="btn-def" (click)="onPromotionCode()" [hidden]="isDisabledVoucher"><i class="bx bx-search-alt"></i></button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="discount > 0">
                                        <td class="order-subtotal"><span>{{'Discount' | translate }}</span></td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">{{ discount| number : '1.3-3'  }}</span>
                                        </td>
                                    </tr>


                                    <!--<tr>
        <td class="order-subtotal"><span>{{'VAT' | translate }}</span></td>
        <td class="order-subtotal-price">
            <span class="order-subtotal-amount">{{ this.mainForm.totalTax| number : '1.3-6'  }}</span>
        </td>
    </tr>-->

                                    <tr *ngIf="mainForm.serviceCharge > 0">
                                        <td class="order-shipping"><span>{{'Delivery' | translate }}</span></td>
                                        <td class="shipping-price">
                                            <span>{{ mainForm.serviceCharge| number : '1.3-3'  }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="total-price"><span>{{'Order_Total' | translate }}</span></td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount">{{ mainForm.amount | number : '1.3-3' }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">

                                <input type="checkbox" class="form-check-input"  #orderNo name="orderNo" >&nbsp;
                                <label for="ServicesTerms">
                                    {{'I_have_read_and_agree_to_the_website' | translate }} <a target="_blank" routerLink="/terms">{{'Terms_and_Conditions' | translate }}</a>*
                                </label>
                                <p>
                                    {{'OTPTXT' | translate }}

                                </p>
                            </div>
                            <button type="submit" class="default-btn" (click)="onOrder(orderNo.checked)"> {{'Place_Order' | translate }}</button>
                            <br />
                            <br />
                            <img src="../../../../assets/img/Payment.jpg" style="max-width:200px" class="align-content-center" />
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- End Checkout Area -->
