export class ItemModule {
    inItemID: number = 0;
    name?: string = '';
    nameAr?: string = '';
    salesPrice?: number = 0;
    wholesalePrice?: number = 0;
    isViewWeb?: boolean = true;
    webDiscountAmount?: number = 0;
    webDiscountFromDate?: Date = new Date();
    webDiscountToDate?: Date = new Date();
    webDiscountPercentage?: number = 0;
    activateWebDiscount?: boolean = true;
    description?: string = '';
    descriptionAr?: string = '';
    photoPath?: string = '';
    barcode?: string = '';
    itemCode?: string = '';
    systemCode?: string = '';
    inUnitID?: number = 0;
    unitName?: string = '';
    unitNameAr?: string = '';
    inCategoryID?: number = 0;
    categoryName?: string = '';
    categoryNameAr?: string = '';
    saleTaxPercentage?: number = 0;


}



export interface ViewItem {

    inItemID: number;
    name?: string ;
    nameAr?: string ;
    salesPrice?: number;
    wholesalePrice?: number;
    isViewWeb?: boolean;
    webDiscountAmount?: number;
    webDiscountFromDate?: Date;
    webDiscountToDate?: Date ;
    webDiscountPercentage?: number;
    activateWebDiscount?: boolean ;
    description?: string ;
    descriptionAr?: string ;
    photoPath?: string ;
    barcode?: string ;
    itemCode?: string ;
    systemCode?: string ;
    inUnitID?: number;
    unitName?: string ;
    unitNameAr?: string ;
    inCategoryID?: number;
    categoryName?: string ;
    categoryNameAr?: string ;
    saleTaxPercentage?: number;


    cartPrice?: number;
    cartQuantity: number;
}


export class ItemUnitsModel {
    inUnitID: number = 0;
    unitName?: string = '';
    unitNameAr?: string = '';
    quantity: number = 0;
    purchasePrice: number = 0;
    salesPrice: number = 0;
    wholesalePrice: number = 0;
    barcode?: string = '';
    systemCode?: string = '';
    itemCode?: string = '';
}
