

<app-homeone-banner></app-homeone-banner>




<section class="products-area ptb-100 bg-f5f5f5">
    <app-gift-tag></app-gift-tag>
</section>

<section class="products-area pb-100">
    <app-occasion-category></app-occasion-category>
</section>


<section class="products-area ptb-100 bg-f5f5f5">
    <app-new-collection></app-new-collection>
</section>



<section class="products-area ptb-100">
    <app-most-sales></app-most-sales>
</section>


<app-facility-style-one></app-facility-style-one>

