import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ItemModule, ViewItem } from '../../../appshared/invoice/item/item.module';
import { CategoryModule } from '../../../appshared/invoice/category/category.module';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../appshared/invoice/category/category.service';
import { ItemService } from '../../../appshared/invoice/item/item.service';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../../../appshared/invoice/cart.service';
import { TagService } from '../../../appshared/invoice/tag/tag.service';
import { TagModule } from '../../../appshared/invoice/tag/tag.module';

@Component({
  selector: 'app-shop-tag',
  templateUrl: './shop-tag.component.html',
  styleUrls: ['./shop-tag.component.scss']
})
export class ShopTagComponent {

    public SelectedLanguage: string = "en";
    private sub: any;
    closeModal: any;
    public apiURL: string = "";
    ComID: number = 1;

    constructor(
        public router: Router, private serv: TagService, private route: ActivatedRoute, private servItem: ItemService, private loader: AppLoaderService, public translate: TranslateService,

        private cartService: CartService, private toastr: ToastrService
    ) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }

    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            if (+params['tagId'] > 0) {
                this.tagID = +params['tagId'];
                this.FillTag();
                this.fillItems();
            }
            else {
                this.router.navigate(['shop']);
               
            }
           
        });


        this.apiURL = environment.apiUrl2 + 'Attachment/Companies/' + this.ComID + '/Items/';
    }


    sortType: string = "Default";
    tagID: number = 0;
    TagName?: string = "";

    currentDate = new Date();


    public dataSourceItems = new MatTableDataSource<ItemModule>();

    datas: any;

    FillTag() {
        // this.loader.open();
        this.serv.getServiceID(this.tagID, this.ComID)
            .subscribe((res: TagModule[]) => {

                this.datas = res;

                if (this.SelectedLanguage != "en") {

                    this.TagName = this.datas.nameAr;
                }
                else {

                    this.TagName = this.datas.name;
                }

                //this.loader.close();
            },
                (err: any) => {
                    //this.loader.close();
                    console.log(err);
                });
    }

    //////////////////////////////////////////


    onSortChange(event: any) {
        this.sortType = event.target.value;
        this.fillItems();
    }

    fillItems() {
        this.dataSourceItems = new MatTableDataSource<ItemModule>();
        this.loader.open();
        this.servItem.getServiceTag(this.ComID, this.tagID, this.sortType)
            .subscribe((res: ItemModule[]) => {
                this.loader.close();

                for (var i = 0; i < res.length; i++) {
                    res[i].webDiscountFromDate = new Date(res[i].webDiscountFromDate);
                    res[i].webDiscountToDate = new Date(res[i].webDiscountToDate);
                }

                this.dataSourceItems.data = res;


            },
                (err: any) => {
                    this.loader.close();
                    console.log(err);
                });
    }




    addToCart(itemM: ItemModule) {

        var price = itemM.salesPrice;
        if (itemM.activateWebDiscount && itemM.webDiscountFromDate < new Date() && itemM.webDiscountToDate > new Date()) {
            price = itemM.webDiscountAmount;
        }

        var product: ViewItem = {


            inItemID: itemM.inItemID,
            name: itemM.name,
            nameAr: itemM.nameAr,
            salesPrice: itemM.salesPrice,
            barcode: itemM.barcode,
            inCategoryID: itemM.inCategoryID,
            description: itemM.description,
            inUnitID: itemM.inUnitID,
            photoPath: itemM.photoPath,
            itemCode: itemM.itemCode,
            unitName: itemM.unitName,
            systemCode: itemM.systemCode,
            categoryNameAr: itemM.categoryNameAr,
            descriptionAr: itemM.descriptionAr,
            categoryName: itemM.categoryName,

            saleTaxPercentage: itemM.saleTaxPercentage,
            isViewWeb: itemM.isViewWeb,
            webDiscountFromDate: itemM.webDiscountFromDate,
            webDiscountToDate: itemM.webDiscountToDate,
            webDiscountAmount: itemM.webDiscountAmount,
            activateWebDiscount: itemM.activateWebDiscount,
            unitNameAr: itemM.unitNameAr,
            webDiscountPercentage: itemM.webDiscountPercentage,
            wholesalePrice: itemM.webDiscountPercentage,

            cartQuantity: 1,
            cartPrice: price


        };

        this.cartService.addToCart(product, 1);

        var transR = this.translate.instant('Your_product_added_to_the_cart');
        this.toastr.success(transR, '');
    }

    

    onClickItem(ItemId: number) {
        this.router.navigate(['product_details', { ItemId: ItemId }]);
    }



    shopFullWidth: number = 1;
    

}
