import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { InvoiceElements, InvoiceElementsModule } from './invoice-elements.module';
import { FormBuilder } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ItemUnitsModel, ViewItem } from '../item/item.module';

@Injectable({
  providedIn: 'root'
})
export class InvoiceElementsService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  quantityFormat: string = '1.3-6';
  priceFormat: string = '1.3-6';
  discountFormat: string = '1.3-6';
  vatFormat: string = '1.3-6';
  totalFormat: string = '1.3-6';
  subtotalFormat: string = '1.3-6';
  netFormat: string = '1.3-6';

  private headers: HttpHeaders;
  constructor(
    private formBuilder: FormBuilder, private http: HttpClient,

    private datePipe: DatePipe,

    private _decimalPipe: DecimalPipe  ) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });



  }




  ////////////////////////////////////////////////////////////////////////////////////////////////////

  transferInvoiceElementsFromModule(invEl: InvoiceElementsModule) {

    var fromDates = this.datePipe.transform(invEl.fromDate, 'yyyy/MM/dd HH:mm:ss');
    var toDates = this.datePipe.transform(invEl.toDate, 'yyyy/MM/dd HH:mm:ss');
    var expiryDates = this.datePipe.transform(invEl.expiryDate, 'yyyy/MM/dd HH:mm:ss');


    const invoiceElements: InvoiceElements = {
      inInvoiceElementsID: invEl.inInvoiceElementsID.toString(), inInvoiceID: invEl.inInvoiceID.toString(), inItemID: invEl.inItemID.toString(), quantity: invEl.quantity.toString(), price: invEl.price.toString(), mainQuantity: invEl.mainQuantity.toString(), details: invEl.details.toString(), inUnitID: invEl.inUnitID.toString(), isReturn: invEl.isReturn.toString(), fromDate: fromDates, toDate: toDates, number: invEl.number.toString(), total: invEl.total.toString(),
      xo_CancelCharge: invEl.xo_CancelCharge.toString(), status: invEl.status.toString(), cancelNotes: invEl.cancelNotes.toString(), cancelInvoiceID: invEl.cancelInvoiceID.toString(), itemCost: invEl.itemCost.toString(), discountPercentage: invEl.discountPercentage.toString(), discount: invEl.discount.toString(), gnCategorySegmentID: invEl.gnCategorySegmentID.toString(), taxPercentage: invEl.taxPercentage.toString(), taxAmount: invEl.taxAmount.toString(), isWithPrice: invEl.isWithPrice.toString(),
      sbarcode: invEl.sbarcode.toString(), totalDiscount: invEl.totalDiscount.toString(), parentInvoiceElementsID: invEl.parentInvoiceElementsID.toString(), relatedInvoiceID: invEl.relatedInvoiceID.toString(), salesSegregation: invEl.salesSegregation.toString(), relatedPOSInvoiceNo: invEl.relatedPOSInvoiceNo.toString(), relatedPOSInvoiceCode: invEl.relatedPOSInvoiceCode.toString(), relatedSourceType: invEl.relatedSourceType.toString(), recordSalesPrice: invEl.recordSalesPrice.toString(),
      recordPurchasePrice: invEl.recordPurchasePrice.toString(), focQuantity: invEl.focQuantity.toString(), mainQuantityFOC: invEl.mainQuantityFOC.toString(), batchNo: invEl.batchNo.toString(), expiryDate: expiryDates, serialNo: invEl.serialNo.toString(), isExpiryDate: invEl.isExpiryDate.toString()
    };

    return invoiceElements;
  }

  addNewElementRow(itemUnitData: ItemUnitsModel[]) {

    let obj1 = {
      inInvoiceElementsID: 0,
      inInvoiceID: 0,
      inItemID: 0,
      quantity: 0,
      price: 0,
      wholesalePrice: 0,
      mainQuantity: 0,
      details: '',
      inUnitID: 0,
      isReturn: false,
      fromDate: new Date(),
      toDate: new Date(),
      number: 0,
      total: 0,
      xo_CancelCharge: 0,
      status: '',
      cancelNotes: '',
      cancelInvoiceID: 0,
      itemCost: 0,
      discountPercentage: 0,
      discount: 0,
      gnCategorySegmentID: 0,
      taxPercentage: 0,
      taxAmount: 0,
      isWithPrice: false,
      sbarcode: '',
      totalDiscount: 0,
      parentInvoiceElementsID: 0,
      relatedInvoiceID: 0,
      salesSegregation: '',
      relatedPOSInvoiceNo: 0,
      relatedPOSInvoiceCode: '',
      relatedSourceType: '',
      recordSalesPrice: 0,
      recordPurchasePrice: 0,
      batchNo: '',
      expiryDate: new Date(),
      isExpiryDate: false,
      focQuantity: 0,
      mainQuantityFOC: 0,
      serialNo: '',
      itemName: '',
      itemNameAr: '',
      unitName: '',
      unitNameAr: '',
      categoryName: '',
      categoryNameAr: '',
      isExpand: false,
      UnitsData: itemUnitData
    };

    return obj1;
  }

  fillViewElementRow(obj: any) {

    var expand = false;
    if (obj.isExpiryDate || (obj.details != '') || (obj.focQuantity > 0) || (obj.batchNo != '') || (obj.serialNo != '')) {
      expand = true;
    }

    return {

      inInvoiceElementsID: obj.inInvoiceElementsID,
      inInvoiceID: obj.inInvoiceID,
      inItemID: obj.inItemID,
      quantity: obj.quantity,
      price: obj.price,
      wholesalePrice: obj.wholesalePrice,
      mainQuantity: obj.mainQuantity,
      details: obj.details,
      inUnitID: obj.inUnitID,
      isReturn: obj.isReturn,
      fromDate: obj.fromDate,
      toDate: obj.toDate,
      number: obj.elementsNumber,
      total: obj.total,
      xo_CancelCharge: obj.xO_CancelCharge,
      status: obj.status,
      cancelNotes: obj.cancelNotes,
      cancelInvoiceID: obj.cancelInvoiceID,
      itemCost: obj.itemCost,
      discountPercentage: obj.discountPercentage,
      discount: obj.elementsDiscount,
      gnCategorySegmentID: obj.gnCategorySegmentID,
      taxPercentage: obj.taxPercentage,
      taxAmount: obj.taxAmount,
      isWithPrice: obj.isWithPrice,
      sbarcode: obj.sBarcode,
      totalDiscount: obj.totalDiscount,
      parentInvoiceElementsID: obj.parentInvoiceElementsID,
      relatedInvoiceID: obj.relatedInvoiceID,
      salesSegregation: obj.salesSegregation,
      relatedPOSInvoiceNo: obj.relatedPOSInvoiceNo,
      relatedPOSInvoiceCode: obj.relatedPOSInvoiceCode,
      relatedSourceType: obj.relatedSourceType,
      recordSalesPrice: obj.recordSalesPrice,
      recordPurchasePrice: obj.recordPurchasePrice,
      batchNo: obj.batchNo,
      expiryDate: obj.expiryDate,
      isExpiryDate: obj.isExpiryDate,
      focQuantity: obj.focQuantity,
      mainQuantityFOC: obj.mainQuantityFOC,
      serialNo: obj.serialNo,
      itemName: obj.itemName,
      itemNameAr: obj.itemNameAr,
      unitName: obj.unitName,
      unitNameAr: obj.unitNameAr,
      categoryName: obj.categoryName,
      categoryNameAr: obj.categoryNameAr,
      isExpand: expand,
      UnitsData: [obj.itemUnitsModelQ as ItemUnitsModel[]]

    };

  }
  
  fillElementRow(obj: any) {


    return {

      inInvoiceElementsID: obj.inInvoiceElementsID,
      inInvoiceID: obj.inInvoiceID,
      inItemID: obj.inItemID,
      quantity: this._decimalPipe.transform(+obj.quantity, this.quantityFormat) ,
      price: this._decimalPipe.transform(+obj.price, this.priceFormat) ,
      wholesalePrice: obj.wholesalePrice,
      mainQuantity: obj.mainQuantity,
      details: obj.details,
      inUnitID: obj.inUnitID,
      isReturn: obj.isReturn,
      fromDate: obj.fromDate,
      toDate: obj.toDate,
      number: obj.number,
      total: this._decimalPipe.transform(+obj.total, this.totalFormat),
      xo_CancelCharge: obj.xo_CancelCharge,
      status: obj.status,
      cancelNotes: obj.cancelNotes,
      cancelInvoiceID: obj.cancelInvoiceID,
      itemCost: obj.itemCost,
      discountPercentage: obj.discountPercentage,
      discount: this._decimalPipe.transform(+obj.discount, this.discountFormat),
      gnCategorySegmentID: obj.gnCategorySegmentID,
      taxPercentage: obj.taxPercentage,
      taxAmount: this._decimalPipe.transform(+obj.taxAmount, this.vatFormat),
      isWithPrice: obj.isWithPrice,
      sbarcode: obj.sbarcode,
      totalDiscount: obj.totalDiscount,
      parentInvoiceElementsID: obj.parentInvoiceElementsID,
      relatedInvoiceID: obj.relatedInvoiceID,
      salesSegregation: obj.salesSegregation,
      relatedPOSInvoiceNo: obj.relatedPOSInvoiceNo,
      relatedPOSInvoiceCode: obj.relatedPOSInvoiceCode,
      relatedSourceType: obj.relatedSourceType,
      recordSalesPrice: obj.recordSalesPrice,
      recordPurchasePrice: obj.recordPurchasePrice,
      batchNo: obj.batchNo,
      expiryDate: obj.expiryDate,
      isExpiryDate: obj.isExpiryDate,
      focQuantity: this._decimalPipe.transform(+obj.focQuantity, this.quantityFormat) ,
      mainQuantityFOC: obj.mainQuantityFOC,
      serialNo: obj.serialNo,
      itemName: obj.itemName,
      itemNameAr: obj.itemNameAr,
      unitName: obj.unitName,
      unitNameAr: obj.unitNameAr,
      categoryName: obj.categoryName,
      categoryNameAr: obj.categoryNameAr,
      isExpand: obj.isExpand,
      UnitsData: obj.UnitsData

    };

  }


  transferElementFromObject(invEl: any): InvoiceElements {
    var fromDates = this.datePipe.transform(invEl.fromDate, 'yyyy/MM/dd HH:mm:ss');
    var toDates = this.datePipe.transform(invEl.toDate, 'yyyy/MM/dd HH:mm:ss');
    var expiryDates = this.datePipe.transform(invEl.expiryDate, 'yyyy/MM/dd HH:mm:ss');


    const invoiceElements: InvoiceElements = {
      inInvoiceElementsID: invEl.inInvoiceElementsID.toString(), inInvoiceID: invEl.inInvoiceID.toString(), inItemID: invEl.inItemID.toString(), quantity: invEl.quantity.toString(), price: invEl.price.toString(), mainQuantity: invEl.mainQuantity.toString(), details: invEl.details.toString(), inUnitID: invEl.inUnitID.toString(), isReturn: invEl.isReturn.toString(), fromDate: fromDates, toDate: toDates, number: invEl.number.toString(), total: invEl.total.toString(),
      xo_CancelCharge: invEl.xo_CancelCharge.toString(), status: invEl.status.toString(), cancelNotes: invEl.cancelNotes.toString(), cancelInvoiceID: invEl.cancelInvoiceID.toString(), itemCost: invEl.itemCost.toString(), discountPercentage: invEl.discountPercentage.toString(), discount: invEl.discount.toString(), gnCategorySegmentID: invEl.gnCategorySegmentID.toString(), taxPercentage: invEl.taxPercentage.toString(), taxAmount: invEl.taxAmount.toString(), isWithPrice: invEl.isWithPrice.toString(),
      sbarcode: invEl.sbarcode.toString(), totalDiscount: invEl.totalDiscount.toString(), parentInvoiceElementsID: invEl.parentInvoiceElementsID.toString(), relatedInvoiceID: invEl.relatedInvoiceID.toString(), salesSegregation: invEl.salesSegregation.toString(), relatedPOSInvoiceNo: invEl.relatedPOSInvoiceNo.toString(), relatedPOSInvoiceCode: invEl.relatedPOSInvoiceCode.toString(), relatedSourceType: invEl.relatedSourceType.toString(), recordSalesPrice: invEl.recordSalesPrice.toString(),
      recordPurchasePrice: invEl.recordPurchasePrice.toString(), focQuantity: invEl.focQuantity.toString(), mainQuantityFOC: invEl.mainQuantityFOC.toString(), batchNo: invEl.batchNo.toString(), expiryDate: expiryDates, serialNo: invEl.serialNo.toString(), isExpiryDate: invEl.isExpiryDate.toString()
    };

    return invoiceElements;
  }

    //inItemID: number;
    //name?: string;
    //nameAr?: string;
    //salesPrice?: number;
    //wholesalePrice?: number;
    //isViewWeb?: boolean;
    //webDiscountAmount?: number;
    //webDiscountFromDate?: Date;
    //webDiscountToDate?: Date;
    //webDiscountPercentage?: number;
    //activateWebDiscount?: boolean;
    //description?: string;
    //descriptionAr?: string;
    //photoPath?: string;
  //  //barcode?: string;
    //itemCode?: string;
    //systemCode?: string;
   // //inUnitID?: number;
    //unitName?: string;
    //unitNameAr?: string;
    //inCategoryID?: number;
    //categoryName?: string;
    //categoryNameAr?: string;
    ////saleTaxPercentage?: number;


    //cartPrice?: number;
   // //cartQuantity: number;

    transferElementFromProduct(invEl: any): InvoiceElements {
        var date = new Date();
        var fromDates = this.datePipe.transform(date, 'yyyy/MM/dd HH:mm:ss');
        var toDates = this.datePipe.transform(date, 'yyyy/MM/dd HH:mm:ss');
        var expiryDates = this.datePipe.transform(date, 'yyyy/MM/dd HH:mm:ss');
        
        var total = (invEl.cartPrice * invEl.cartQuantity);

        var discountPercentage = 0;

        if (invEl.activateWebDiscount == true && invEl.webDiscountFromDate < date && invEl.webDiscountToDate > date) {
            discountPercentage = invEl.webDiscountAmount;
        }


        var discountAmt = (total * (discountPercentage / 100));

        discountAmt = +this._decimalPipe.transform(discountAmt, this.discountFormat).replace(/,/g, '');

        total = total - discountAmt;

        var vatAmt = (total * (invEl.saleTaxPercentage / 100));

        vatAmt = +this._decimalPipe.transform(vatAmt, this.vatFormat).replace(/,/g, '');

        total = total + vatAmt;

        total = +this._decimalPipe.transform(total, this.totalFormat).replace(/,/g, '');



        var TaxAmount = ((invEl.saleTaxPercentage / 100) * invEl.cartPrice);

        var total = invEl.cartQuantity * (TaxAmount + invEl.cartPrice);

        const invoiceElements: InvoiceElements = {
            inInvoiceElementsID: '0', inInvoiceID: '0', inItemID: invEl.inItemID.toString(), quantity: invEl.cartQuantity.toString(), price: invEl.salesPrice.toString(), mainQuantity: invEl.cartQuantity.toString(), details: '', inUnitID: invEl.inUnitID.toString(), isReturn: 'false', fromDate: fromDates, toDate: toDates, number: '0', total: total.toString(),
            xo_CancelCharge: '0', status: '', cancelNotes: '', cancelInvoiceID: '0', itemCost: '0', discountPercentage: discountPercentage.toString(), discount: discountAmt.toString(), gnCategorySegmentID: '0', taxPercentage: invEl.saleTaxPercentage.toString(), taxAmount: vatAmt.toString(), isWithPrice: 'false',
            sbarcode: invEl.barcode.toString(), totalDiscount: discountAmt.toString(), parentInvoiceElementsID: '0', relatedInvoiceID: '0', salesSegregation: '', relatedPOSInvoiceNo: '0', relatedPOSInvoiceCode: '', relatedSourceType: '', recordSalesPrice: invEl.salesPrice.toString(),
            recordPurchasePrice: '0', focQuantity: '0', mainQuantityFOC: '0', batchNo: '', expiryDate: expiryDates, serialNo: '', isExpiryDate: 'false'
    };

    return invoiceElements;
  }


}
