import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppComfirmComponent } from './app-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { Direction } from '@angular/cdk/bidi';

interface confirmData {
    title?: string ,
  message?: string
}

@Injectable()
export class AppConfirmService {

    constructor(private dialog: MatDialog,
        public translate: TranslateService) { }

    titleConfirm: string = "";
    messageConfirm: string = "";


    public confirm(data: confirmData = {}): Observable<boolean> {
        this.titleConfirm = this.translate.getParsedResult(this.translate.translations[this.translate.currentLang], 'Confirm')
        this.messageConfirm =  this.translate.getParsedResult(this.translate.translations[this.translate.currentLang], 'Are_you_sure')

        var direction: Direction;
        direction = 'ltr';
        if (this.translate.currentLang == 'ar') {
            direction = 'rtl';
        }
      
        data.title = data.title || this.titleConfirm;
        data.message = data.message || this.messageConfirm;

    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
        width: '380px',
        direction: direction,
      disableClose: true,
      data: {title: data.title, message: data.message}
    });
    return dialogRef.afterClosed();
  }
}
