import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Filter, Invoice, InvoiceModule } from './invoice.module';
import { DeleteInvoiceElementsS, InvoiceElements } from '../invoice-elements/invoice-elements.module';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient,
        private datePipe: DatePipe) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getServiceId(id: number, comID: number, invType: string): Observable<InvoiceModule[]> {
        return this.http.get<InvoiceModule[]>(environment.apiURL + 'POS/Web/INInvoice/InvoiceID/?id=' + id + "&comID=" + comID + "&invType=" + invType);
    }

    getServiceTrack(comID: number, orderNo: number, phoneNo: string): Observable<InvoiceModule[]> {
        return this.http.get<InvoiceModule[]>(environment.apiURL + 'POS/Web/INInvoice/TrackInvoice/?comID=' + comID + "&orderNo=" + orderNo + "&phoneNo=" + phoneNo);
    }


    postMultiService(multiInvoice: Invoice[], multiInvoiceElements: InvoiceElements[], multiRecoed: any[], multiPolicy: any[], multiRecoedCheque: any[], multiPolicyCheque: any[], multiInvoice2: any[], multiInvoiceElements2: any[], multiDeleteInvoiceElementsS: any[], multiInvoiceUpdate: any[], multiInvoiceElementsUpdate: any[]) {
        let Invoices = {
            INInvoiceS: multiInvoice,
            INInvoiceElementS: multiInvoiceElements,
            ACRecordS: multiRecoed,
            ACPolicyS: multiPolicy,
            ACRecordChequeS: multiRecoedCheque,
            ACPolicyChequeS: multiPolicyCheque,
            INInvoice2S: multiInvoice2,
            INInvoiceElement2S: multiInvoiceElements2,
            DeleteInvoiceElementsS: multiDeleteInvoiceElementsS,
            INInvoiceUpdateS: multiInvoiceUpdate,
            INInvoiceElementUpdateS: multiInvoiceElementsUpdate
        };

        return this.http.post(environment.apiURL + 'POS/Web/INInvoice/postMulti/', Invoices, { responseType: 'text' });
    }



    transferInvoiceFromModule(inv: InvoiceModule) {

        var dates = this.datePipe.transform(inv.date, 'yyyy/MM/dd HH:mm:ss');
        var dueDates = this.datePipe.transform(inv.dueDate, 'yyyy/MM/dd HH:mm:ss');
        var authorizeDates = this.datePipe.transform(inv.authorizeDate, 'yyyy/MM/dd HH:mm:ss');
        var payDates = this.datePipe.transform(inv.payDate, 'yyyy/MM/dd HH:mm:ss');
        var addUserDates = this.datePipe.transform(inv.addUserDate, 'yyyy/MM/dd HH:mm:ss');
        var updateUserDates = this.datePipe.transform(inv.updateUserDate, 'yyyy/MM/dd HH:mm:ss');
        var lpoDates = this.datePipe.transform(inv.lpoDate, 'yyyy/MM/dd HH:mm:ss');
        var submitDates = this.datePipe.transform(inv.submitDate, 'yyyy/MM/dd HH:mm:ss');


        const invoice: Invoice = {
            inInvoiceID: inv.inInvoiceID.toString(), inInvoiceNo: inv.inInvoiceNo.toString(), acAccountID: inv.acAccountID.toString(), date: dates, amount: inv.amount.toString(), discount: inv.discount.toString(), paid: inv.paid.toString(), payingWay: inv.payingWay.toString(), gnBankID: inv.gnBankID.toString(), chequeNo: inv.chequeNo.toString(), dueDate: dueDates, acYearID: inv.acYearID.toString(), lock: inv.lock.toString(), notes: inv.notes.toString(), mgCompanyID: inv.mgCompanyID.toString(), invoiceType: inv.invoiceType.toString(), authorizeID: inv.authorizeID.toString(), authorizeDate: authorizeDates, accountantID: inv.accountantID.toString(), payDate: payDates, authorizedStatus: inv.authorizedStatus.toString(), type: inv.type.toString(), isPaid: inv.isPaid.toString(), document: inv.document.toString(), supplierID: inv.supplierID.toString(), supplierAmount: inv.supplierAmount.toString(), mgBranchID: inv.mgBranchID.toString(), rejected: inv.rejected.toString(), attachmentTXT: inv.attachmentTXT.toString(), sellerID: inv.sellerID.toString(), sourceNo: inv.sourceNo.toString(), source: inv.source.toString(), statusType: inv.statusType.toString(), clientName: inv.clientName.toString(), phoneNo: inv.phoneNO.toString(), otherPhoneNo: inv.otherPhoneNo.toString(), email: inv.email.toString(), address: inv.address.toString(),
            promotionCode: inv.promotionCode.toString(), percentage: inv.percentage.toString(), serviceCharge: inv.serviceCharge.toString(), attachment: inv.attachment.toString(), mgposid: inv.mgposid.toString(), posInvoiceNo: inv.posInvoiceNo.toString(), posInvoiceCode: inv.posInvoiceCode.toString(), payCash: inv.payCash.toString(), payVisa: inv.payVisa.toString(), lpoDate: lpoDates, lpoNo: inv.lpoNo.toString(), branchINInvoiceID: inv.branchINInvoiceID.toString(), fromBranchID: inv.fromBranchID.toString(), toBranchID: inv.toBranchID.toString(), gnCategoryFolderID: inv.gnCategoryFolderID.toString(), rootsNo: inv.rootsNo.toString(), smsIssue: inv.smsIssue.toString(), smsReady: inv.smsReady.toString(), smsRemind: inv.smsRemind.toString(), smsSubmit: inv.smsSubmit.toString(), acAccountAttentionID: inv.acAccountAttentionID.toString(), rate: inv.rate.toString(), gnCurrencyID: inv.gnCurrencyID.toString(), transferFees: inv.transferFees.toString(), manageBy: inv.manageBy.toString(), submitDate: submitDates, inDeliveryAddressID: inv.inDeliveryAddressID.toString(), deliveryAddressTXTAr: inv.deliveryAddressTXTAr.toString(), deliveryAddressTXTEn: inv.deliveryAddressTXTEn.toString(), deliveryMethod: inv.deliveryMethod.toString(), totalPaid: inv.totalPaid.toString(), refNo: inv.refNo.toString(),
            totalTax: inv.totalTax.toString(), inReceiveAddressID: inv.inReceiveAddressID.toString(), receiveAddressTXTAr: inv.receiveAddressTXTAr.toString(), receiveAddressTXTEn: inv.receiveAddressTXTEn.toString(), delegateID: inv.delegateID.toString(), addUserID: inv.addUserID.toString(), addUserDate: addUserDates, updateDateID: inv.updateDateID.toString(), updateUserDate: updateUserDates, isClear: inv.isClear.toString(), settlementAmount: inv.settlementAmount.toString(), latitude: inv.latitude.toString(), longitude: inv.longitude.toString(), driverName: inv.driverName.toString(), vehicleNo: inv.vehicleNo.toString(), deliveryAddress: inv.deliveryAddress.toString(), sourceAr: inv.sourceAr.toString(), gnCategoryPromotionCodeID: inv.gnCategoryPromotionCodeID.toString(), totalReceipt: inv.totalReceipt.toString(), totalRefund: inv.totalRefund.toString(), mgBranchStockID: inv.mgBranchStockID.toString(), receiptNo: inv.receiptNo.toString(), invoiceTypeAr: inv.invoiceTypeAr.toString(), receiveName: inv.receiveName.toString(), receiveDialingCodes: inv.receiveDialingCodes.toString(), receivePhoneNo: inv.receivePhoneNo.toString(), receiveAddress: inv.receiveAddress.toString(), dialingCodes: inv.dialingCodes.toString()
        };

        return invoice;
    }




    getCheckOTP(comID: number, OTPCode: string, PhoneNo: string, dialingCodes: string) {

        return this.http.get(environment.apiURL + 'POS/Web/INInvoice/CheckOTP/?comID=' + comID + "&OTPCode=" + OTPCode + "&PhoneNo=" + PhoneNo + "&DialingCode=" + dialingCodes , { responseType: 'text' });

    }



    getSendNotifyOTP(comID: number, PhoneNo: string, DialingCode: string) {

        return this.http.get(environment.apiURL + 'POS/Web/INInvoice/SendNotifyOTP/?comID=' + comID + "&PhoneNo=" + PhoneNo + "&DialingCode=" + DialingCode , { responseType: 'text' });

    }


    getThawaniPayment(comID: number, invocieID: number) {

        return this.http.get(environment.apiURL + 'POS/Web/INInvoice/ThawaniPaymentRequest/?comID=' + comID + "&invocieID=" + invocieID);

    }
     


}

