<!-- Start Page Title Area -->
<div class="page-title-area mt-0" style="background-image: url('../../../../assets/img/page-title-bg.jpg'); ">
    <h1>{{'Order_Tracking' | translate }}</h1>
    <ul>
        <li><a routerLink="/">{{'Home' | translate }}</a></li> /
        <li>{{'Order_Tracking' | translate }}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Order Tracking Area -->
<section class="order-tracking-area ptb-100">
    <div class="container">
        <div class="order-tracking-content">
      
                <div class="form-group">
                    <label>{{'Order_No' | translate }}</label>
                    <input type="text" class="form-control" #orderNo name="orderNo">
                </div>

                <div class="form-group">
                    <label>{{'Phone_No' | translate }} </label>
                    <input type="text" required ng2TelInput #phone_number name="phone_number" class="form-control"  (countryChange)="onCountryChange($event)" [ng2TelInputOptions]="telOptions" />
                </div>
            <br />
                <button type="button" class="default-btn" (click)="onTrack(orderNo.value,phone_number.value)">{{'Track_Order' | translate }}</button>
      
        </div>
    </div>
</section>
<!-- End Order Tracking Area -->
