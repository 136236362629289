import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppLoaderService } from '../../../common/service/app-loader/app-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService } from '../../../appshared/invoice/invoice/invoice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent {

    public SelectedLanguage: string = "en";
    constructor(private router: Router, private toastr: ToastrService,  public translate: TranslateService) {
        if (this.translate.currentLang != undefined) {

            this.SelectedLanguage = this.translate.currentLang;
        }
    }



    onTrack(orderNo: string, phoneNo: string) {

        if (orderNo == null || +orderNo == 0 || phoneNo.length < 6) {

            var transR = this.translate.instant('Please_fill_the_required_fields');
            this.toastr.warning(transR, '');
        }
        else {
            this.router.navigate(['tracking_details', { orderNo: +orderNo, phoneNo: phoneNo }]);

        }
    }


    ///////////////////////////////////////////////////////////////////////////
    Dialing: string = "968";
    telOptions = { initialCountry: 'om', preferredCountries: ['om'] };
    phone_number_standard: any;
    onCountryChange(event) {
        this.Dialing = event.dialCode;
    }
}
