import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { WebpageModule } from './webpage.module';

@Injectable({
  providedIn: 'root'
})
export class WebpageService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  }


  getService(ComID: number): Observable<WebpageModule[]> {
      return this.http.get<WebpageModule[]>(environment.apiURL + 'POS/Web/WBWebpage/search/' + ComID);
  }

    getServiceType(type: string, comID: number): Observable<WebpageModule[]> {
        return this.http.get<WebpageModule[]>(environment.apiURL + 'POS/Web/WBWebpage/searchPage/?comID=' + comID + '&type=' + type)
            .pipe(

        );
    }

}
