
export class InvoiceModule {
    inInvoiceID: number = 0;
    inInvoiceNo?: number = 0;
    acAccountID?: number = 0;
    date?: Date = new Date();
    amount?: number = 0;
    discount?: number = 0;
    paid?: number = 0;
    payingWay?: string = '';
    gnBankID?: number = 0;//////
    chequeNo?: string = '';
    dueDate?: Date = new Date();
    acYearID?: number = 0;
    lock?: boolean = true;
    notes?: string = '';
    mgCompanyID?: number = 0;
    invoiceType?: string = '';
    authorizeID?: number = 0;
    authorizeDate?: Date = new Date();
    accountantID?: number = 0;
    payDate?: Date = new Date();
    authorizedStatus?: string = '';
    type?: string = '';
    isPaid?: boolean = true;
    document?: string = '';
    supplierID?: number = 0;
    supplierAmount?: number = 0;
    mgBranchID?: number = 0;
    rejected?: boolean = true;
    attachmentTXT?: string = '';
    sellerID?: number = 0;
    sourceNo?: number = 0;
    source?: string = '';
    statusType?: string = '';
    clientName?: string = '';
    phoneNO?: string = '';
    otherPhoneNo?: string = '';
    email?: string = '';
    address?: string = '';
    promotionCode?: string = '';
    percentage?: string = '';
    serviceCharge?: number = 0;
    attachment?: string = '';
    mgposid?: number = 0;
    posInvoiceNo?: number = 0;
    posInvoiceCode?: string = '';
    payCash?: number = 0;
    payVisa?: number = 0;
    lpoDate?: Date = new Date();
    lpoNo?: string = '';
    branchINInvoiceID?: number = 0;
    fromBranchID?: number = 0;
    toBranchID?: number = 0;
    gnCategoryFolderID?: number = 0;
    rootsNo?: number = 0;
    smsIssue?: boolean = true;
    smsReady?: boolean = true;
    smsRemind?: boolean = true;
    smsSubmit?: boolean = true;
    acAccountAttentionID?: number = 0;
    rate?: number = 0;
    gnCurrencyID?: number = 0;
    transferFees?: number = 0;
    manageBy?: number = 0;
    submitDate?: Date = new Date();
    inDeliveryAddressID?: number = 0;
    deliveryAddressTXTAr?: string = '';
    deliveryAddressTXTEn?: string = '';
    deliveryMethod?: string = '';
    totalPaid?: number = 0;
    refNo?: string = '';
    totalTax?: number = 0;
    inReceiveAddressID?: number = 0;
    receiveAddressTXTAr?: string = '';
    receiveAddressTXTEn?: string = '';
    delegateID?: number = 0;
    addUserID?: number = 0;
    addUserDate?: Date = new Date();
    updateDateID?: number = 0;
    updateUserDate?: Date = new Date();
    isClear?: boolean = true;
    settlementAmount?: number = 0;
    latitude?: string = '';
    longitude?: string = '';
    driverName?: string = '';
    vehicleNo?: string = '';
    deliveryAddress?: string = '';
    sourceAr?: string = '';
    gnCategoryPromotionCodeID?: number = 0;
    totalReceipt?: number = 0;
    totalRefund?: number = 0;
    mgBranchStockID?: number = 0;
    receiptNo?: string = '';
    invoiceTypeAr?: string = '';

    dialingCodes?: string = '';
    receiveName?: string = '';
    receiveDialingCodes?: string = '';
    receivePhoneNo?: string = '';
    receiveAddress?: string = '';
}

export class ViewInvoiceDetailsModule {
    inInvoiceID: number = 0;
    inInvoiceNo?: number = 0;
    acAccountID?: number = 0;
    date?: Date = new Date();
    amount?: number = 0;
    discount?: number = 0;
    paid?: number = 0;
    payingWay?: string = '';
    gnBankID?: number = 0;//////
    chequeNo?: string = '';
    dueDate?: Date = new Date();
    acYearID?: number = 0;
    lock?: boolean = true;
    notes?: string = '';
    mgCompanyID?: number = 0;
    invoiceType?: string = '';
    authorizeID?: number = 0;
    authorizeDate?: Date = new Date();
    accountantID?: number = 0;
    payDate?: Date = new Date();
    authorizedStatus?: string = '';
    type?: string = '';
    isPaid?: boolean = true;
    document?: string = '';
    supplierID?: number = 0;
    supplierAmount?: number = 0;
    mgBranchID?: number = 0;
    rejected?: boolean = true;
    attachmentTXT?: string = '';
    sellerID?: number = 0;
    sourceNo?: number = 0;
    source?: string = '';
    statusType?: string = '';
    clientName?: string = '';
    phoneNO?: string = '';
    otherPhoneNo?: string = '';
    email?: string = '';
    address?: string = '';
    promotionCode?: string = '';
    percentage?: string = '';
    serviceCharge?: number = 0;
    attachment?: string = '';
    mgposid?: number = 0;
    posInvoiceNo?: number = 0;
    posInvoiceCode?: string = '';
    payCash?: number = 0;
    payVisa?: number = 0;
    lpoDate?: Date = new Date();
    lpoNo?: string = '';
    branchINInvoiceID?: number = 0;
    fromBranchID?: number = 0;
    toBranchID?: number = 0;
    gnCategoryFolderID?: number = 0;
    rootsNo?: number = 0;
    smsIssue?: boolean = true;
    smsReady?: boolean = true;
    smsRemind?: boolean = true;
    smsSubmit?: boolean = true;
    acAccountAttentionID?: number = 0;
    rate?: number = 0;
    gnCurrencyID?: number = 0;
    transferFees?: number = 0;
    manageBy?: number = 0;
    submitDate?: Date = new Date();
    inDeliveryAddressID?: number = 0;
    deliveryAddressTXTAr?: string = '';
    deliveryAddressTXTEn?: string = '';
    deliveryMethod?: string = '';
    totalPaid?: number = 0;
    refNo?: string = '';
    totalTax?: number = 0;
    inReceiveAddressID?: number = 0;
    receiveAddressTXTAr?: string = '';
    receiveAddressTXTEn?: string = '';
    delegateID?: number = 0;
    addUserID?: number = 0;
    addUserDate?: Date = new Date();
    updateDateID?: number = 0;
    updateUserDate?: Date = new Date();
    isClear?: boolean = true;
    settlementAmount?: number = 0;
    latitude?: string = '';
    longitude?: string = '';
    driverName?: string = '';
    vehicleNo?: string = '';
    deliveryAddress?: string = '';
    mgBranchStockID?: number = 0;
    branchStockName?: string = '';
}


export interface Filter {
    name: string;
    value: string;
    valueID: string;
    type: string;
}





export interface Invoice {
    inInvoiceID?: string;
    inInvoiceNo?: string;
    acAccountID?: string;
    date?: string;
    amount?: string;
    discount?: string;
    paid?: string;
    payingWay?: string;
    gnBankID?: string;
    chequeNo?: string;
    dueDate?: string;
    acYearID?: string;
    lock?: string;
    notes?: string;
    mgCompanyID?: string;
    invoiceType?: string;
    authorizeID?: string;
    authorizeDate?: string;
    accountantID?: string;
    payDate?: string;
    authorizedStatus?: string;
    type?: string;
    isPaid?: string;
    document?: string;
    supplierID?: string;
    supplierAmount?: string;
    mgBranchID?: string;
    rejected?: string;
    attachmentTXT?: string;
    sellerID?: string;
    sourceNo?: string;
    source?: string;
    statusType?: string;
    clientName?: string;
    dialingCodes?: string;
    phoneNo?: string;
    otherPhoneNo?: string;
    email?: string;
    address?: string;
    promotionCode?: string;
    percentage?: string;
    serviceCharge?: string;
    attachment?: string;
    mgposid?: string;
    posInvoiceNo?: string;
    posInvoiceCode?: string;
    payCash?: string;
    payVisa?: string;
    lpoDate?: string;
    lpoNo?: string;
    branchINInvoiceID?: string;
    fromBranchID?: string;
    toBranchID?: string;
    gnCategoryFolderID?: string;
    rootsNo?: string;
    smsIssue?: string;
    smsReady?: string;
    smsRemind?: string;
    smsSubmit?: string;
    acAccountAttentionID?: string;
    rate?: string;
    gnCurrencyID?: string;
    transferFees?: string;
    manageBy?: string;
    submitDate?: string;
    inDeliveryAddressID?: string;
    deliveryAddressTXTAr?: string;
    deliveryAddressTXTEn?: string;
    deliveryMethod?: string;
    totalPaid?: string;
    refNo?: string;
    totalTax?: string;
    inReceiveAddressID?: string;
    receiveAddressTXTAr?: string;
    receiveAddressTXTEn?: string;
    delegateID?: string;
    addUserID?: string;
    addUserDate?: string;
    updateDateID?: string;
    updateUserDate?: string;
    isClear?: string;
    settlementAmount?: string;
    latitude?: string;
    longitude?: string;
    driverName?: string;
    vehicleNo?: string;
    deliveryAddress?: string;
    sourceAr?: string;
    gnCategoryPromotionCodeID?: string;
    totalReceipt?: string;
    totalRefund?: string;
    mgBranchStockID?: string;
    receiptNo?: string;
    invoiceTypeAr?: string;
    receiveName?: string;
    receiveDialingCodes?: string;
    receivePhoneNo?: string;
    receiveAddress?: string;

}

