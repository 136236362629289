import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSelectModule } from '@angular/material/select';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from "@angular/material/table";
import { HomeComponent } from './appviews/pages/home/home.component';
import { AboutComponent } from './appviews/pages/about/about.component';
import { PrivacyComponent } from './appviews/pages/privacy/privacy.component';
import { TermsComponent } from './appviews/pages/terms/terms.component';
import { FAQComponent } from './appviews/pages/faq/faq.component';
import { ContactComponent } from './appviews/pages/contact/contact.component';
import { HomeoneBannerComponent } from './appviews/pages/home/homeone-banner/homeone-banner.component';
import { NavbarStyleComponent } from './common/navbar-style/navbar-style.component';
import { AppConfirmService } from './common/service/app-confirm/app-confirm.service';
import { AppLoaderService } from './common/service/app-loader/app-loader.service';
import { AppComfirmComponent } from './common/service/app-confirm/app-confirm.component';
import { AppLoaderComponent } from './common/service/app-loader/app-loader.component';
import { FooterComponent } from './common/footer/footer.component';
import { ShopComponent } from './appviews/shop/shop/shop.component';
import { ShopFilterComponent } from './appviews/shop/shop-filter/shop-filter.component';
import { ProductDetailsComponent } from './appviews/shop/product-details/product-details.component';
import { CartComponent } from './appviews/shop/cart/cart.component';
import { CheckoutComponent } from './appviews/shop/checkout/checkout.component';
import { TrackingComponent } from './appviews/shop/tracking/tracking.component';
import { TrackingDetailsComponent } from './appviews/shop/tracking-details/tracking-details.component';
import { InsertOTPDialogComponent } from './appviews/tools/insert-otpdialog/insert-otpdialog.component';
import { PaymentResultComponent } from './appviews/shop/payment-result/payment-result.component';
import { FacilityStyleOneComponent } from './common/facility-style-one/facility-style-one.component';
import { OccasionCategoryComponent } from './common/occasion-category/occasion-category.component';
import { ChocolateProductComponent } from './common/chocolate-product/chocolate-product.component';
import { VaseProductComponent } from './common/vase-product/vase-product.component';
import { PerfumeProductComponent } from './common/perfume-product/perfume-product.component';
import { NewCollectionComponent } from './common/new-collection/new-collection.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NotFoundPageComponent } from './appviews/pages/not-found-page/not-found-page.component';
import { MostSalesComponent } from './common/most-sales/most-sales.component';
import { GiftTagComponent } from './common/gift-tag/gift-tag.component';
import { ShopTagComponent } from './appviews/shop/shop-tag/shop-tag.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeoneBannerComponent,
    FacilityStyleOneComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    PrivacyComponent,
    TermsComponent,
    FAQComponent,
    ContactComponent,
        NavbarStyleComponent,
        AppLoaderComponent,
        AppComfirmComponent,
        ShopComponent,
        ShopFilterComponent,
        ProductDetailsComponent,
        CartComponent,
        CheckoutComponent,
        TrackingComponent,
        TrackingDetailsComponent,
        InsertOTPDialogComponent,
        PaymentResultComponent,
        OccasionCategoryComponent,
        ChocolateProductComponent,
        VaseProductComponent,
        PerfumeProductComponent,
        NewCollectionComponent,
        NotFoundPageComponent,
        MostSalesComponent,
        GiftTagComponent,
        ShopTagComponent
  ],
  imports: [
      BrowserModule,
      MatSelectModule,
    AppRoutingModule,
      BrowserAnimationsModule,
      CarouselModule,
      HttpClientModule,
    NgxScrollTopModule,
    FormsModule,
      NgxPaginationModule,
      Ng2TelInputModule,
      NgOtpInputModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
      MatDialogModule,
      BsDatepickerModule.forRoot(),
      ToastrModule.forRoot(),
      SelectDropDownModule,
      MatProgressSpinnerModule,
      MatTableModule
  ],
    providers: [BsDatepickerConfig, AppConfirmService, DatePipe, DecimalPipe,
        AppLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
